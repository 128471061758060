.two-column {
  display: grid;
  grid-template-columns: var(--column-one-width, 318px) 1fr;
  height: 100vh;
  overflow: hidden;
}

.two-column__one {
  background: var(--color-bw-50);
  overflow: hidden;
}

.two-column__two {
  background: var(--color-grey-50);
  overflow: hidden;
}

.message {
  padding: var(--spacing-3);
}

.message__type,
.message__positions-select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message__positions-select {
  max-width: 50%;
}

.message__select-button {
  max-width: 75%;
}

.message__message-input {
  width: 100%;
}

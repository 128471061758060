.rotation-group-preview {
  position: absolute;
  top: 0;
  z-index: 9999;
  display: grid;
  grid-template-columns: repeat(7, var(--spacing-10));
  grid-template-rows: max-content;
  grid-auto-rows: var(--spacing-10);
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(2.8px);
  box-shadow: -5px 22px 31.1622px rgba(28, 28, 28, 0.09);
  border-radius: var(--spacing-3);
  padding: var(--spacing-5);
  justify-content: stretch;
  gap: var(--spacing-3);
  margin: var(--spacing-2) 0 0 calc(var(--spacing-2) * -1);
}

.rotation-group-preview__item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--color-grey-200);
  font: var(--font-sm-medium);
  overflow: hidden;
  border-radius: 1.48px;
  padding: var(--spacing-3);
}

.rotation-group-preview__item::before {
  content: "";
  width: 2px;
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  background: currentColor;
}

.rotation-group-preview__item-label {
  color: var(--color-grey-800);
}

.rotation-group-preview__item--resting-day {
  background-color: var(--color-grey-200);
  color: var(--color-grey-700);
}

.rotation-group-preview__header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-grey-800);
  font: var(--font-text-xxs);
}

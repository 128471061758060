.shift-schedule {
  background: var(--color-grey-50);
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.shift-schedule--employee {
  padding-left: var(--spacing-5);
}

.shift-schedule__sidebar-wrapper {
  /* needed for position of reset filters button */
  position: relative;
  height: 100%;
  overflow: hidden;
}

.shift-schedule__sidebar {
  /* 8px is also the default for Firefox, the only browser supporting scrollbar-width */
  --scrollbar-width: 8px;

  background: var(--color-grey-50);
  height: 100%;
  overflow-y: scroll;

  /* spacing-15 to leave space for the button on the bottom */
  padding: 0 var(--spacing-1) var(--spacing-15) var(--spacing-1);
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

.shift-schedule__sidebar::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.shift-schedule__sidebar:hover {
  scrollbar-color: var(--color-grey-200) transparent;
}

.shift-schedule__sidebar:hover::-webkit-scrollbar-thumb {
  background: var(--color-grey-200);
  border-radius: var(--spacing-1);
}

.shift-schedule__main {
  background: var(--color-grey-50);
  border-radius: var(--spacing-1);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
}

.shift-schedule__main:only-child {
  margin: 0 0 0 var(--spacing-6);
}

.shift-schedule__container {
  padding: var(--spacing-5);
}

.shift-schedule__container--sidebar-collapsed {
  --column-one-width: 0;
}

.shift-schedule__controls {
  margin-bottom: var(--spacing-3);
}

.shift-schedule__calendar {
  overflow: auto;
}

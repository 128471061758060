.navigation-button {
  color: var(--color-grey-500);
  display: flex;
  justify-content: space-between;
  opacity: 0.6;
  padding: var(--spacing-4) var(--spacing-7);
  text-decoration: none;
  transition: background var(--transition-default), color var(--transition-default);
}

.navigation__button--active {
  background: var(--color-primary-100);
  color: var(--color-primary-500);
  opacity: 1;
}

.navigation__button--disabled {
  cursor: default;
}

.navigation-button__icon {
  justify-content: center !important;
  width: var(--spacing-8);
  height: var(--spacing-8);
}

.cell-employment {
  align-items: center;
  display: grid;
  grid-template-columns: min-content auto;
  text-align: left;
}

.cell-employment__avatar {
  margin: 0 var(--spacing-3) 0 0;
  grid-row: span 2;
}

.cell-employment__staff-number {
  font: var(--font-sm-regular);
  color: var(--color-grey-500);
}

.cell-employment__name {
  text-overflow: ellipsis;
  overflow: hidden;
}

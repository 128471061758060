.checkbox-list-with-paging-and-filtering {
  font: var(--font-sm-regular);
}

.checkbox-list-with-paging-and-filtering__pagination {
  border-top: rgba(0, 0, 0, 0.1) 1px solid;
}

.checkbox-list-with-paging-and-filtering__top-bar {
  align-items: center;
  border-bottom: var(--color-grey-200) 1px solid;
  display: flex;
  flex-direction: row;
  padding: var(--spacing-2) var(--spacing-3);
  font: var(--font-sm-medium);
}

.checkbox-list-with-paging-and-filtering__icon {
  margin: 0 0.25ch 0 0;
  color: var(--color-grey-500);
}

.checkbox-list-with-paging-and-filtering__search {
  border-bottom: var(--color-grey-200) 0 solid;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.35s ease, border-bottom-width;
  transition-delay: 0s, 0.35s;
}

.checkbox-list-with-paging-and-filtering__search-container {
  align-items: center;
  color: var(--color-grey-800);
  display: flex;
  flex: 2;
  overflow: hidden;
}

.checkbox-list-with-paging-and-filtering__search-input {
  border: 0;
  color: inherit;
  flex: 1;
  font: inherit;
  min-width: 0;
  outline: 0;
  padding-left: var(--spacing-1);
}

.checkbox-list-with-paging-and-filtering__select-all {
  align-items: center;
  color: var(--color-grey-800);
  display: grid;
  flex: 1;
  grid-gap: 0 var(--spacing-2);
  grid-template-columns: auto 1fr;
}

.filter-popup {
  border-radius: var(--spacing-2);
  box-shadow: 0 5px 34px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.filter-popup__header {
  align-items: center;
  color: var(--color-grey-900);
  display: flex;
  font: var(--font-base-medium);
  justify-content: space-between;
  padding: var(--spacing-3) var(--spacing-4);
}

.filter-popup__close-button {
  margin: 0 0 0 var(--spacing-4);
}

.filter-popup__search {
  align-items: center;
  color: var(--color-grey-700);
  display: flex;
  flex-direction: row;
  font: var(--font-xs-regular);
  padding: 0 var(--spacing-4);
}

.filter-popup__search-icon {}

.filter-popup__search-input {
  border: none;
  color: inherit;
  flex: 1;
  margin-left: var(--spacing-2);
  outline: none;

  /* custom 5px y-padding for better accessibility */
  padding: 0.3125rem 0;
}

.filter-popup__search:first-child .filter-popup__search-input {
  /* custom 11px y-padding for better accessibility */
  padding: 0.6875rem 0;
}

.filter-popup__list {
  border-top: 1px solid var(--color-grey-200);
  font: var(--font-sm-medium);
  list-style: none;
  margin: 0;
  max-height: 400px;
  overflow: auto;
  padding: var(--spacing-4);
}

.filter-popup__list:first-child {
  border-top: 0;
}

.filter-popup__list-item {
  color: var(--color-grey-800);
  text-align: left;
}

.filter-popup__list-item:not(:last-child) {
  margin-bottom: var(--spacing-3);
}

.filter-popup__list-item-content {
  align-items: center;
  display: flex;
}

.filter-popup__list-item-content > span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-popup__list-item-checkbox {
  margin-right: var(--spacing-3);
}

.filter-popup__list-item-checkbox:checked {
  color: var(--shyftplan-green);
}

.alert {
  border-radius: var(--spacing-1);
  box-sizing: border-box;
  padding: var(--spacing-4);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: var(--font-sm-medium);
  background-color: var(--alert-background-color);
  max-width: 100%;
  width: 624px;
}

.alert__content {
  padding: 0 var(--spacing-3);
}

.alert__group {
  display: flex;
  align-items: flex-start;
}

.alert--info {
  --title-color: var(--color-info-700);
  --message-color: var(--color-info-600);
  --icon-color: var(--color-info-500);
  --icon-background-color: transparent;
  --trailing-icon-color: var(--color-info-700);
  --trailing-icon-background: var(--color-info-100);
  --alert-background-color: var(--color-info-50);
}

.alert--success {
  --title-color: var(--color-success-800);
  --message-color: var(--color-success-700);
  --icon-color: var(--color-bw-50);
  --icon-background-color: var(--color-success-500);
  --trailing-icon-color: var(--color-success-700);
  --trailing-icon-background: var(--color-success-100);
  --alert-background-color: var(--color-success-50);
}

.alert--warning {
  --title-color: var(--color-warning-800);
  --message-color: var(--color-warning-700);
  --icon-color: var(--color-warning-500);
  --icon-background-color: transparent;
  --trailing-icon-color: var(--color-warning-700);
  --trailing-icon-background: var(--color-warning-100);
  --alert-background-color: var(--color-warning-50);
}

.alert--error {
  --title-color: var(--color-error-800);
  --message-color: var(--color-error-700);
  --icon-color: var(--color-bw-50);
  --icon-background-color: var(--color-error-500);
  --trailing-icon-color: var(--color-error-700);
  --trailing-icon-background: var(--color-error-100);
  --alert-background-color: var(--color-error-50);
}

.alert__title {
  color: var(--title-color);
}

.alert__message {
  color: var(--message-color);
  font-size: var(--font-sm-regular);
  margin-top: 0.5rem;
}

.alert__icon {
  fill: var(--icon-color);
  background-color: var(--icon-background-color);
  min-width: 20px;
  border-radius: 9999px;
  box-sizing: border-box;
}

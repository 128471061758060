.popover-content {
  background: var(--color-bw-50);
}

.popover-content__heading {
  background: var(--color-bw-50);
  border-radius: var(--spacing-2) var(--spacing-2) 0 0;
  font: var(--font-sm-medium);
  padding: 0 0 var(--spacing-3);
  color: var(--color-grey-900);
  border-bottom: 1px solid var(--color-grey-200);
  margin: 0 0 var(--spacing-3);
}

.popover-content__list {
  margin: 0;
}

.popover-content__item-header {
  color: var(--color-grey-700);
  font: var(--font-sm-medium);
  margin: 0;
}

.popover-content__item-header:not(:first-child) {
  margin: var(--spacing-4) 0 0;
}

.popover-content__item {
  color: var(--color-grey-700);
  font: var(--font-sm-regular);
  margin: 0;
  text-align: left;
}

.timeframe-control {
  display: flex;
  flex-direction: row;
  position: relative;
}

.timeframe-control__today {
  --background-color-button: var(--color-grey-100) !important;

  flex-shrink: 2;
  margin-right: var(--spacing-1);
  border-radius: 4px;
  transition: background var(--transition-fast);
}

.timeframe-control__today:hover,
.timeframe-control__today:focus {
  --background-color-button: var(--color-grey-200) !important;
}

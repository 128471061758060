.header-cell {
  padding: var(--table-gap);
  background-color: var(--header-cell-background, var(--color-grey-50));
}

.header-cell__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.header-cell__content--stretch {
  justify-content: left;
}

.header-cell__filter-icon {
  margin: 0 var(--spacing-1) 0 0;
}

.header-cell__sort-icon {
  margin: 0 0 0 var(--spacing-1);
}

.header-cell__label {
  margin: 0 var(--spacing-3);
}

.header-cell__filter-wrapper {
  position: relative;
}

.header-cell__popup {
  background-color: var(--color-bw-50);
  display: flex;
  min-width: min-content;
  max-width: 300px;
  position: absolute;
  left: 0;
  top: calc(100% + var(--table-vertical-gap));
  width: 100%;
  z-index: 9999;
}

.header-cell:first-child {
  border-radius: var(--spacing-3) 0 0 var(--spacing-3);
}

.header-cell:last-child {
  border-radius: 0 var(--spacing-3) var(--spacing-3) 0;
}

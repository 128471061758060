.evaluations {
  max-width: 900px;
  width: calc(100vw - 2 * var(--spacing-10));
}

.evaluations__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.evaluations__list-item {
  overflow: hidden;
}

.evaluations__list-item:not(:first-child) {
  border-top: var(--color-grey-100) 1px solid;
}

.evaluations__list-item-button {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  gap: 0 var(--spacing-3);
  justify-content: space-between;
  outline: 0;
  padding: var(--spacing-3) var(--spacing-4);
  text-align: left;
  transition: background var(--transition-fast);
  width: 100%;
}

.evaluations__list-item-button:hover {
  background: var(--color-grey-100);
}

.evaluations__evaluation {
  grid-column: 1 / -1;
}

.transition-slide-enter,
.transition-slide-leave-to {
  max-height: 0;
  opacity: 0;
  transition: max-height var(--transition-fast), opacity var(--transition-fast);
}

.transition-slide-enter-to,
.transition-slide-leave {
  max-height: 9999px;
  opacity: 1;
  transition: max-height var(--transition-fast), opacity var(--transition-fast);
}

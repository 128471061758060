.filter-popup-employments {
  max-width: 300px;
}

.filter-popup-employments__item-avatar {
  margin-right: var(--spacing-2);
}

.filter-popup-employments__item:not(:last-child) {
  margin-bottom: var(--spacing-4);
}

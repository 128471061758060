.section-pay {
  /* noop */
}

.section-pay__section {
  grid-column: 1 / -1;
}

.section-pay__section:not(:last-child) {
  margin-bottom: var(--spacing-8);
}

.section-pay__button {
  grid-column: 1 / -1;
  margin: var(--spacing-4) auto 0;
}

.paygrades {
  grid-template-columns: 1fr;
  padding: var(--spacing-4) 0;
  width: calc(100vw - 2 * var(--spacing-10));
}

.paygrades__none {
  font: var(--font-base-medium);
  margin: 0;
  padding: var(--spacing-7) var(--spacing-4);
  text-align: center;
}

.paygrades__section {
  padding: 0 var(--spacing-4);
}

.paygrades__button {
  justify-self: center;
}

.paygrades__spinner {
  margin: var(--spacing-5) auto;
  font-size: 2em;
}

.paygrades__placeholder--section {
  --placeholder-height: var(--spacing-19);
  --placeholder-width: calc(100% - 2 * var(--spacing-4));
  margin: 0 auto;
}

.paygrades__placeholder--button {
  --placeholder-height: var(--spacing-10);
  --placeholder-width: 120px;
  margin: 0 auto;
}

.main-view {
  display: grid;
  grid-template-areas:
    'navigation header'
    'navigation view';
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content 1fr;
  height: 100vh;

  /* legacy */
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 767px) {
  .main-view {
    grid-template-areas:
      'header'
      'view'
      'navigation';
    grid-template-columns: none;
    grid-template-rows: max-content 1fr max-content;
  }
}

.main-view__header {
  grid-area: header;
}

.main-view__navigation {
  grid-area: navigation;
}

.main-view__view {
  background: var(--color-grey-50);
  display: flex;
  grid-area: view;
  overflow: hidden;
}

.main-view__main {
  flex: 3;
  overflow: hidden;
  transition: flex var(--transition-duration-default);
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .main-view__main--chat-open {
    flex: 0;
  }
}

.input-radio {
  --shadow-focus: 0 0 0 1px var(--color-bw-50), 0 0 0 2px var(--color-primary-500);
  --icon-size: var(--spacing-4);

  display: inline-flex;
  flex-flow: column nowrap;
  position: relative;
}

.input-radio__input-wrapper {
  min-width: calc(var(--icon-size) + 2px);
  min-height: calc(var(--icon-size) + 4px);
  align-items: stretch;
  display: flex;
  flex: 1;
  position: relative;
}

.input-radio__input {
  height: 0;
  width: 0;
  margin-right: calc(var(--icon-size) + var(--spacing-2));
  position: absolute;
  top: 1px;
  left: 2px;
}

.input-radio__input::before {
  box-sizing: border-box;
  position: absolute;
  content: " ";
  border-radius: 50%;
  border: 1px solid var(--color-grey-300);
  height: var(--icon-size);
  width: var(--icon-size);
  top: 1px;
  cursor: pointer;
  background: transparent;
  transition: background-color var(--transition-fast), border-color var(--transition-fast);
}

.input-radio__input::after {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 6px;
  left: 5px;
  background: var(--color-bw-50);
  height: 6px;
  width: 6px;
  border-radius: var(--spacing-2);
  transform: scale(0);
  transition: transform var(--transition-fast);
}

.input-radio__label {
  color: var(--color-grey-900);
  cursor: pointer;
  display: flex;
  font: var(--font-sm-medium);
  justify-content: inherit;
  align-items: flex-start;
  margin-left: calc(var(--spacing-2) + var(--icon-size));
  transition: color var(--transition-fast);
}

.input-radio__icon {
  margin-right: var(--spacing-2);
}

.input-radio__text {
  display: flex;
  flex-direction: column;
}

.input-radio__description {
  color: var(--color-grey-500);
  font-weight: var(--font-weight-regular);
  transition: color var(--transition-fast);
}

.input-radio--disabled .input-radio__input::before {
  color: var(--color-grey-300);
  transition: color var(--transition-fast);
}

.input-radio--disabled .input-radio__label {
  cursor: default;
  opacity: 0.4;
}

.input-radio--invalid .input-radio__input {
  color: var(--color-grey-500);
  transition: color var(--transition-fast);
}

.input-radio__input:hover::before {
  background: var(--color-grey-100);
  transition: background-color var(--transition-fast);
}

.input-radio__input:focus::before {
  box-shadow: var(--shadow-focus);
}

.input-radio__input:disabled::before {
  background: var(--color-grey-200);
  border-color: var(--color-grey-300);
  cursor: default;
  transition: background-color var(--transition-fast), border-color var(--transition-fast);
}

.input-radio__input:checked::before {
  background: var(--color-primary-400);
  border-color: var(--color-primary-400);
  transition: background-color var(--transition-fast), border-color var(--transition-fast);
}

.input-radio__input:checked:hover::before {
  background: var(--color-primary-500);
  border-color: var(--color-primary-500);
  transition: background-color var(--transition-fast), border-color var(--transition-fast);
}

.input-radio__input:checked::after {
  transform: scale(1);
}

.input-radio__input:checked:disabled::before {
  background: var(--color-primary-500);
  color: var(--color-primary-500);
  transition: background-color var(--transition-fast), color var(--transition-fast);
  opacity: 0.4;
  cursor: default;
}

.input-radio__input:checked:disabled + .input-radio__label {
  cursor: default;
}

.loader {
  display: inline-block;
  margin: 3.5em auto 2.5em;
  position: relative;
  text-align: center;
  width: 100%;
}

.loader__svg {
  animation: loaderbounce 1s cubic-bezier(0.51, 0.23, 0.02, 0.96) infinite;
  fill: var(--shyftplan-green);
  height: 32px;
  position: relative;
  width: 100%;
  z-index: 10;
}

.loader::after {
  animation: loadershadow 1s infinite;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  bottom: 1px;
  content: '';
  display: block;
  height: 4px;
  left: calc(50% - 9px);
  position: absolute;
  width: 20px;
}

@keyframes loaderbounce {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.85, 1.1) translateY(-15px);
  }
}

@keyframes loadershadow {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(0.7, 1);
    background: rgba(black, 0.05);
  }
}

.filter-box-tag__draggable {
  /* noop */
}

.filter-box-tag__draggable:global(.draggable--handle) {
  width: 100%;
  min-height: 25px;
  height: 25px;
  max-width: 300px;
  border: 1px solid var(--color-grey-200);
  opacity: 1;
}

.filter-box-tag__draggable:global(.draggable--handle) input {
  visibility: hidden;
}

.requester-list {
  /* noop */
}

.requester-list__offerer:not(:first-child) {
  margin-top: var(--spacing-3);
}

.requester-list__requester {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--spacing-2) 0;
  padding: var(--spacing-4);
}

.requester-list__requester-title {
  background: var(--color-grey-100);
  color: var(--color-grey-900);
  font: var(--font-sm-medium);
  margin: calc(-1 * var(--spacing-4)) calc(-1 * var(--spacing-3)) 0;
  padding: var(--spacing-3) var(--spacing-3);
}


.transition-slide-enter.base-dialog__backdrop,
.transition-slide-leave-to.base-dialog__backdrop {
  opacity: 0;
}

.transition-slide-enter-to.base-dialog__backdrop,
.transition-slide-leave.base-dialog__backdrop {
  opacity: 1;
}

.base-dialog__container {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: center;
  left: 0;
  padding: var(--spacing-10);
  position: fixed;
  top: 0;
  transition: opacity var(--transition-fast);
  width: 100vw;
  z-index: 99999;
}

.base-dialog__backdrop {
  top: 0;
  left: 0;
  background: rgba(46, 53, 61, 0.8);
  border: 0;
  height: 100%;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.base-dialog {
  border-radius: var(--spacing-2);
  background: var(--color-bw-50);
  display: flex;
  flex-flow: column nowrap;
  max-height: 80vh;
  min-width: var(--dialog-max-width-sm);
  overflow: hidden;
  max-width: 100vw;
  width: var(--dialog-max-width);
  z-index: 2;
}

.base-dialog__title-body-separator {
  margin: 0 var(--spacing-6);
  border-top: 1px solid var(--color-grey-200);
}

.base-dialog__body {
  flex: 1;
  padding: 0 var(--spacing-6);
  max-height: 100%;
  overflow: hidden auto;
  width: 100%;
}

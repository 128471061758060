.button {
  display: flex;
}

.button__button {
  background: var(--color-athens-light);
  border: 0;
  margin: 0;
  min-height: var(--spacing-10);
  min-width: var(--spacing-13);
  outline: none;
  padding: var(--spacing-1) var(--spacing-3);
  transition: background var(--transition-fast);
}

.button__button:hover,
.button__button:focus {
  background: var(--color-athens);
}

.button__button {
  border-radius: 3px;
}

.button__button:not(:disabled) {
  cursor: pointer;
}

.button__button--selected {
  background: var(--color-shyftplan-green);
}

.button__button--selected:hover,
.button__button--selected:focus {
  background: var(--color-shyftplan-green);
}

.button__button-icon {
  color: var(--color-comet);
  font-size: var(--spacing-5);
  transition: color var(--transition-fast);
  width: var(--spacing-7);
  height: var(--spacing-7);
  line-height: var(--spacing-7);
}

.button__button--selected .button__button-icon {
  color: var(--color-white);
}

.button__tooltip {
  display: inline-block;
}

.button {
  align-items: center;
  color: var(--color-grey-900);
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 calc(-1 * var(--spacing-7));
  padding: var(--spacing-4) var(--spacing-7);
  text-decoration: none;
  transition: background var(--transition-fast), color var(--transition-fast);
}

.button--active,
.button:not(.button--disabled):hover {
  background: var(--color-primary-100);
  color: var(--color-primary-600);
}

.button--disabled {
  color: var(--color-grey-600);
  cursor: default;
  opacity: 0.6;
}

.button__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button__icon {
  color: var(--color-primary-600);
  justify-content: center !important;
  width: var(--spacing-8);
  height: var(--spacing-8);
}

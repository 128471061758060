.subheader {
  align-items: center;
  border-bottom: var(--color-grey-200) 1px solid;
  display: flex;
  justify-content: space-between;

  /* 51px */
  height: 3.1875rem;
  padding: 0 var(--spacing-7);
}

.subheader__title {
  color: var(--color-grey-900);
  font: var(--font-xl-medium);
  letter-spacing: var(--font-heading-letter-spacing-h345);
  margin: 0;
}

.subheader__right {
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-3);
}

.breaks {
  align-items: center;
  display: grid;
  gap: var(--spacing-3) var(--spacing-6);
}

.breaks__unpaid-break-input {
  grid-column: 1 / -1;
}

.breaks__centered-button {
  grid-column: 1 / -1;
  justify-content: center;
  display: flex;
  margin: var(--spacing-4) auto 0;
}

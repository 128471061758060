.dropdown-state {
  display: inline-block;
  /* align dropdown menu with cell not with itself */
  position: initial;
}

.dropdown-state__pill {
  background: 0;
  border-radius: var(--spacing-2);
  display: flex;
  margin: calc(-1 * var(--spacing-2));
  padding: var(--spacing-1) var(--spacing-3);
  transition: background var(--transition-timing-function-default) calc(var(--transition-duration-default) / 2),
}

.dropdown-state__pill--info:hover {
  background: var(--color-info-100);
}

.dropdown-state__pill--error:hover {
  background: var(--color-error-50);
}

.link-button {
  --color-background: transparent;
  --color-background-hover: var(--color-peppermint);
  --color-text: var(--shyftplan-green);
  --disabled-opacity: 0.6;

  display: inline-flex;
  align-items: center;
  padding: var(--spacing-2) var(--spacing-7);
  font: var(--font-body-small);
  text-decoration: none;
  color: var(--color-text);
  background: var(--color-background);
  border: 0;
  border-radius: 9999px;
  outline: 0;
  box-shadow: inset 0 0 0 2px var(--color-text);
  cursor: pointer;
  transition:
    background var(--transition-default),
    box-shadow var(--transition-default),
    color var(--transition-default);
}

.link-button:focus {
  outline: none;
}

.link-button:hover {
  --color-background: var(--color-background-hover);
}

.link-button:active {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.1);
}

/* colors */

.link-button--outer-space {
  --color-text: var(--color-outer-space);
  --color-background-hover: var(--color-grey-200);
  --color-background-active: var(--color-grey-400);
}

.link-button--shyftplan-green {
  --color-text: var(--shyftplan-green);
  --color-background-hover: #44bb4420;
  --color-background-active: #44bb4480;
}

.filter-timeframe {
  /* noop */
}

.filter-timeframe__datepicker {
  border-radius: var(--spacing-3);
  padding-bottom: var(--spacing-3);
  /* hack to enable a height transition -> https://css-tricks.com/using-css-transitions-auto-dimensions/#aa-technique-1-max-height */
  max-height: 500px;
}

.filter-timeframe__toggle {
  width: 100%;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  color: var(--color-shyftplan-green);
  text-align: left;
  margin: var(--spacing-2) 0 0;
  padding: var(--spacing-2) var(--spacing-2);
  border-radius: var(--spacing-3);
  transition: background var(--transition-default),
    box-shadow var(--transition-default), margin var(--transition-default);
}

.filter-timeframe__toggle:hover {
  background: var(--color-peppermint);
}

.filter-timeframe__toggle:active {
  box-shadow: inset 0px 0px 0px 9999px rgba(0, 0, 0, 0.1);
}

.filter-timeframe__toggle--is-opened {
  margin-bottom: var(--spacing-2);
}

/* enter/leave animation */
:global(.filter-timeframe__datepicker-transition-enter-active),
:global(.filter-timeframe__datepicker-transition-leave-active) {
  transition: max-height var(--transition-default),
    opacity var(--transition-default);
}

:global(.filter-timeframe__datepicker-transition-enter),
:global(.filter-timeframe__datepicker-transition-leave-to) {
  max-height: 0;
  opacity: 0;
}

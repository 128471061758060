.filter-box {
  margin-bottom: var(--spacing-5);
  overflow: hidden;
  position: relative;
  box-shadow: var(--shadow-base);
}

.filter-box__header {
  align-items: center;
  background: var(--color-white);
  color: var(--color-comet);
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  border-radius: var(--spacing-3);
  padding: var(--spacing-3) var(--spacing-6);
}

.filter-box__icon {
  justify-content: center !important;
}

.filter-box__body {
  transform-origin: top;
  transition:
    opacity var(--transition-default),
    max-height var(--transition-default);
}

.filter-box__summary-badge {
  color: var(--color-info-600);
  background: var(--color-info-100);
  padding: var(--spacing-1) var(--spacing-3);
  margin-left: var(--spacing-2);
}

.filter-box__button-reset {
  outline: 0;
  background: 0;
  border: 0;
  cursor: pointer;
  border-radius: 9999px;
  padding: 0;
}

.filter-box__button-reset:hover {
  background: var(--color-info-200);
}

.filter-box__button-reset:active {
  background: var(--color-info-600);
  color: var(--color-white);
}

.filter-box__inner {
  /* noop */
}

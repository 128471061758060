.section-settings__row {
  /* noop */
}

.section-settings__row--full-width {
  grid-column: span 2;
}

.section-settings__row--with-tooltip {
  display: flex;
  align-items: flex-end;
}

.section-settings__tooltip-icon {
  font-size: var(--icon-font-size-lg);
  margin-left: var(--spacing-4);
  display: flex;
  align-items: flex-end;
  color: var(--color-grey-700);
}

.header {
  align-items: center;
  background: var(--color-grey-50);
  display: grid;
  grid-gap: var(--spacing-3) var(--spacing-5);
  grid-template-columns: max-content 1fr max-content;
  padding: var(--spacing-3) var(--spacing-5);
}

.header__shiftplan-selector-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header__shiftplan-selector {
  justify-self: center;
  margin-right: var(--spacing-3);
}

.header__title {
  color: var(--color-primary-600);
  font: var(--font-lg-regular);
  line-height: 2rem;
  margin: 0;
}

.header__actions {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--spacing-3) var(--spacing-4);
}

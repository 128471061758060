.filter-popup-timeframe {
  display: flex;
  flex-direction: column;
  font: var(--font-sm-medium);
  padding: var(--spacing-2) var(--spacing-4);
  min-width: 282px;
}

.filter-popup-timeframe__radio-wrapper {
  align-items: flex-start;
  color: var(--color-grey-800);
  display: flex;
  margin-bottom: var(--spacing-3);
}

.filter-popup-timeframe__radio-input {
  margin-right: var(--spacing-3);
}

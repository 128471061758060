.navigation {
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
}

.navigation__list {
  list-style: none;
  padding: 0;
  width: 100%;
}

.navigation__list-item {
  font: var(--font-xl-medium);
}

.navigation__button-enabled {
  color: var(--color-grey-900);
  opacity: 1;
}

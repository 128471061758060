.cell-attachment {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-7);
  justify-content: center;

  /* so the tooltip is visible */
  overflow: initial;
}

.cell-attachment__tooltip {
  display: inline-block;
}

.cell-attachment__link {
  color: var(--color-grey-900);
  text-decoration: none;
}

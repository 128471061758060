.pagination {
  /* noop */
}

.pagination__list {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--spacing-1);
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  white-space: nowrap;
}

.pagination__list > li:first-child {
  margin-right: var(--spacing-2);
}

.pagination__list > li:last-child {
  margin-left: var(--spacing-2);
}

.pagination__button,
.pagination__ellipsis {
  align-items: center;
  color: var(--color-grey-500);
  display: flex;
  justify-content: center;
  line-height: var(--spacing-10);
  height: var(--spacing-10);
  width: var(--spacing-10);
}

.pagination__button {
  background: 0;
  border: 0;
  cursor: pointer;
  transition: background 0.2s ease;
  border-bottom-width: var(--spacing-05);
  border-bottom-style: solid;
  border-bottom-color: transparent;
}

.pagination__button--arrow {
  border: 1px solid var(--color-grey-300);
  border-radius: var(--spacing-1);
  width: 32px;
  height: 32px;
}

.pagination__button:disabled {
  color: var(--color-grey-300);
  cursor: initial;
}

.pagination__button:not(:disabled, .pagination__button--arrow):hover,
.pagination__button--selected {
  color: var(--color-primary-600);
  border-bottom: var(--spacing-05) solid var(--color-primary-500);
}

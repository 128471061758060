.details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  overflow-y: scroll;
}

.details__alert {
  width: 100%;
}

.details__employment-spinner,
.details__employment {
  margin: var(--spacing-2) 0 0;
}

.details__employment-spinner {
  --spinner-size: 30px;
}

.details__message {
  margin: var(--spacing-2) 0 0;
}

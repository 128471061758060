.section {
  /* noop */
}

.section--inherited {
  background: var(--color-grey-200);
}

.section--dense .section__select {
  flex: 0 0 100%;
}

.section__sub {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  /* not supported in Safari < 14.1, won't look too bad though */
  gap: var(--spacing-3) var(--spacing-6);
}

.section__sub:not(:first-child) {
  margin-top: var(--spacing-3);
}

.section__select {
  background: 0;
  flex: 2;
}

.section__input {
  flex: 1;
  min-width: 10ch;
  overflow: hidden;
  position: relative;
}

.section__input-value {
  width: 100%;
}

.section__tooltip {
  position: absolute;
  top: 0;
  right: 0;
}

.section__icon-inherited {
  color: var(--color-warning-700);
}

.section__button {
  margin: var(--spacing-3) auto 0;
}

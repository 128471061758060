.day {
  background: none;
  border: 0;
  color: var(--color-grey-900);
  display: flex;
  justify-content: center;
  outline: 0;
  padding: 0 var(--spacing-1);
  transition: background var(--transition-fast);
}

.day--interval-selected {
  background-color: var(--color-primary-100);
}

.day--single-selected {}

.day__label {
  border-radius: 9999px;
  cursor: pointer;
  display: block;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  transition: background var(--transition-fast), color var(--transition-fast);
  width: 30px;
}

.day:disabled {
  opacity: 0.5;
}

.day:disabled .day__label {
  cursor: default;
}

/*
  focus/hover state
*/
.day:not(.day:disabled):not(.day--interval-selected):not(.day--single-selected):hover .day__label,
.day:not(.day:disabled):not(.day--interval-selected):not(.day--single-selected):not(.day--interval-selection-boundary-end):not(.day--interval-selection-boundary-start):focus .day__label,
.day.day--pre-selection-interval-hovered:not(.day--interval-selected) {
  background-color: var(--color-athens-light);
  color: var(--color-grey-900);
}

.day--pre-selection-interval-hovered-start {
  background: linear-gradient(-90deg, var(--color-athens-light), var(--color-athens-light) 50%, transparent 51%, transparent);
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.day--pre-selection-interval-hovered-end {
  background: linear-gradient(90deg, var(--color-athens-light), var(--color-athens-light) 50%, transparent 51%, transparent);
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

/* only show border when start and end of selection do not match */
.day--interval-selection-boundary-end:not(.day--interval-selection-boundary-start) {
  background: linear-gradient(90deg, var(--color-primary-100), var(--color-primary-100) 50%, transparent 51%, transparent);
}

/* only show border when start and end of selection do not match */
.day--interval-selection-boundary-start:not(.day--interval-selection-boundary-end) {
  background: linear-gradient(-90deg, var(--color-primary-100), var(--color-primary-100) 50%, transparent 51%, transparent);
}

/* single day selection should not have interval start or end border */
.day--interval-selection-boundary-start.day--interval-selection-boundary-end:not(.day--pre-selection-interval-hovered) {
  background: none;
}

.day__label--interval-selection-boundary,
.day__label--single-selected {
  background-color: var(--color-shyftplan-green);
  border-radius: 9999px;
  color: var(--color-white);
}

.day--first-column {
  border-radius: 9999px 0 0 9999px;
  padding-left: 0;
}

.day--last-column {
  border-radius: 0 9999px 9999px 0;
  padding-right: 0;
}

.employment-with-avatar {
  align-items: center;
  color: var(--color-grey-900);
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0 var(--spacing-3);
  font: var(--font-base-regular);
}

/* Used in columnAssigned */
.employment-with-avatar__vacancy {
  border-radius: 9999px;
  height: var(--avatar-size, 1.875rem);
  width: var(--avatar-size, 1.875rem);
}

.employment-with-avatar__avatar-with-indicator {
  display: flex;
  position: relative;
}

.employment-with-avatar__container {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  width: 100%;
}

.employment-with-avatar__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.employment-with-avatar__staff-number {
  color: var(--color-grey-700);
  font: var(--font-sm-regular);
  margin-top: var(--spacing-1);
}

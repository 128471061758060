.row {
  display: flex;
  justify-content: space-between;
}

.row__information {
  align-items: center;
  color: var(--color-grey-900);
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-3);
  font: var(--font-base-regular);
}

.dialog-absence__attachment-link {
  color: var(--shyftplan-green);
  overflow: hidden;
  text-decoration: none;
}

.dialog-absence__attachment-link:hover {
  color: var(--color-grey-900);
}

.dialog-absence__error-list {
  color: var(--color-error-700);
  font: var(--font-caption);
  list-style-position: inside;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dialog-absence__side-by-side {
  align-items: flex-start;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-4);
  grid-template-columns: 1fr 1fr;
  align-items: end;
}

.dialog-absence__input-toggle {
  align-items: center;
  display: grid !important;
  grid-template-columns: 1fr 0 max-content;
}

.button-toolbar {
  border: 0;
  margin: 0;
  outline: none;
  padding: var(--spacing-2);
  border-radius: var(--spacing-1);
  background-color: var(--background-color);
  color: var(--text-color);
  width: 38px;
}

.button-toolbar--outer-space {
  --background-color: var(--outer-space);
  --hover-color: #585d64;
  --text-color: var(--color-bw-50);
}

.button-toolbar--shyftplan-green {
  --background-color: var(--shyftplan-green);
  --hover-color: #60c560;
  --text-color: var(--color-bw-50);
}

.button-toolbar:hover {
  background-color: var(--hover-color);
}

.button-toolbar:disabled {
  color: var(--neutral-comet);
  opacity: 0.6;
}

.button-toolbar__icon {
  color: var(--text-color);
}

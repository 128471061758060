.sidebar {
  /* noop */
}

.sidebar__header {
  backdrop-filter: blur(9px);

  /* is color-athens at 85% opacity */
  background: rgba(231, 232, 237, 0.85);
  padding: calc(3 * var(--spacing-10)) 0 var(--spacing-3);
  position: sticky;
  top: 0;
  z-index: 9999;
}

.sidebar__title {
  color: var(--color-grey-500);
  font: var(--font-sm-regular);
  text-transform: uppercase;
  margin: 0 0 var(--spacing-2);
}

.sidebar__unassigned {
  align-items: center;
  color: var(--color-grey-700);
  display: flex;
  font: var(--font-sm-medium);
  justify-content: space-between;
}

.sidebar__unassigned-filter-button {
  --button-icon-text-color: var(--color-grey-900) !important;

  height: var(--spacing-8);
  justify-content: center !important;
  width: var(--spacing-8);
}

.sidebar__unassign-button {
  margin: 0 0 var(--spacing-12);
}

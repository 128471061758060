.step-wrapper {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  margin: 0 calc(var(--spacing-12) + var(--spacing-11));
}

.step-wrapper__heading {
  font: var(--font-3xl-medium);
  letter-spacing: var(--font-heading-letter-spacing-h345);
  margin: 0;
  padding-top: var(--spacing-12);
  text-align: center;
}

.step-wrapper__footer {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -50%, var(--color-grey-50) 66%);
  bottom: 0;
  padding: 0 0 var(--spacing-12);
  position: sticky;
}

.rotation-wizard__left {
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
  height: 100%;
  background-color: var(--color-grey-50);
}

.rotation-wizard__main {
  height: 100%;
  overflow-y: auto;
}

.rotation-wizard__brand {
  height: var(--spacing-11);
  margin: var(--spacing-7);
}

.rotation-wizard__back {
  align-items: center;
  color: var(--color-primary-500);
  display: flex;
  font: var(--font-base-regular);
  margin: var(--spacing-7);
  text-decoration: none;
}

.rotation-wizard__back-icon {
  height: var(--spacing-6);
  justify-content: center !important;
  margin: 0 var(--spacing-1) 0 0;
  width: var(--spacing-6);
}

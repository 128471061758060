:root {
  --tooltip-tip-size: 5px;
}

.base-tooltip {
  --tooltip-text-transform-left: -50%;
  --tooptip-tip-left: 50%;
  --tooltip-text-transform-top: calc(-100% - var(--tooltip-tip-size, 5px));
  --tooltip-background: var(--color-grey-900);
  --tooltip-color: var(--color-bw-50);
}

.base-tooltip__popover {
  --tooltip-background: var(--color-bw-50);
  --tooltip-color: var(--color-grey-700);

  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
}

.base-tooltip__text {
  background: var(--tooltip-background);
  color: var(--tooltip-color);
  backdrop-filter: blur(var(--spacing-1));
  border-radius: var(--spacing-1);
  font: var(--font-sm-regular);
  padding: var(--spacing-4);
  position: absolute;
  transform: translate(var(--tooltip-text-transform-left), var(--tooltip-text-transform-top));
  transition: opacity var(--transition-fast);
  z-index: 99999;
  white-space: nowrap;
}

.base-tooltip__text::before {
  border: transparent solid;
  border-top-color: var(--tooltip-background);
  border-width: var(--tooltip-tip-size) var(--tooltip-tip-size) 0 var(--tooltip-tip-size);
  content: '';
  height: 0;
  left: 0;
  margin: 0 calc(var(--tooptip-tip-left) - var(--tooltip-tip-size, 5px));
  position: absolute;
  top: 100%;
  width: 0;
}

/* used when tooltip is too close to right edge of the screen */
.base-tooltip__text--left {
  --tooltip-text-transform-left: -90%;
  --tooptip-tip-left: 90%;
}

/* used when tooltip is too close to left edge of the screen */
.base-tooltip__text--right {
  --tooltip-text-transform-left: 10%;
  --tooptip-tip-left: 10%;
}

.transition-slide-enter.base-tooltip__text,
.transition-slide-leave-to.base-tooltip__text {
  /* FAQ: because of some CSS modules ordering issue, we need to forcefully override the opacity */
  opacity: 0 !important;
}

.transition-slide-enter-to.base-tooltip__text,
.transition-slide-leave.base-tooltip__text {
  opacity: 1;
}

.base-tooltip__text--bottom {
  --tooltip-text-transform-top: calc(-100% + var(--tooltip-tip-size, 5px));
}

.base-tooltip__text--bottom::before {
  border-color: transparent;
  border-bottom-color: var(--tooltip-background);
  border-width: 0 var(--tooltip-tip-size) var(--tooltip-tip-size) var(--tooltip-tip-size);
  bottom: 100%;
  top: initial;
}

.notification {
  padding: var(--spacing-3);
}

.notification__type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification__select-button {
  max-width: 70%;
}

.notification__message-input {
  width: 100%;
}

.select-panel {
  position: fixed;
  background-color: var(--color-bw-50);
  border-radius: var(--spacing-2);
  border: 1px solid var(--color-grey-300);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  list-style: none;
  margin: 0;
  max-height: 25vh;
  overflow-y: auto;
  padding: 0;
  text-align: left;
  transform-origin: top;
  transition: transform var(--open-transition-easing) var(--open-transition-duration);
  z-index: 1000;
}

.transition-slide-enter.select-panel,
.transition-slide-leave-to.select-panel {
  transform: scaleY(0);
}

.transition-slide-enter-to.select-panel,
.transition-slide-leave.select-panel {
  transform: scaleY(1);
}

.select-panel__menu-item {
  background-color: var(--color-bw-50);
  color: var(--color-grey-700);
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font: var(--font-sm-regular);
  padding: var(--spacing-2) var(--spacing-3);
  text-align: left;
  text-decoration: none;
  transition: all var(--transition-fast);
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  width: 100%;
  border: none;
}

.select-panel__menu-item-content {
  display: block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
}

.select-panel__menu-item-checkmark {
  flex-shrink: 0;
  margin-left: var(--spacing-2);
  color: var(--color-primary-500);
}

.select-panel__menu-item:focus:not(.select-panel__menu-item--disabled) {
  background-color: var(--color-primary-50);
  outline: none;
}

.select-panel__menu-item:not(.select-panel__menu-item--disabled):hover {
  background-color: var(--color-grey-50);
}

.select-panel__menu-item--disabled {
  cursor: default;
  color: var(--color-grey-500);
}

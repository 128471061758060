.section-heading {
  align-items: center;
  background: var(--color-grey-50);
  display: grid;
  font: var(--font-base-medium);
  grid-template-columns: 1fr max-content;
  min-height: var(--spacing-10);
  padding: var(--spacing-4);
  border-bottom: 1px solid var(--color-grey-200);
  border-top-left-radius: var(--spacing-1);
  border-top-right-radius: var(--spacing-1);
}

.section-heading--with-context {
  grid-template-columns: max-content 1fr max-content;
}

.section-heading__context {
  color: var(--color-grey-700);
  opacity: 0.5;
}

.section-heading__summary {
  color: var(--color-primary-600);
}

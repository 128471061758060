.controls {
  align-items: center;
  background: var(--color-grey-50);
  display: flex;
  justify-content: space-between;
}

.controls__group {
  display: grid;
  column-gap: var(--spacing-3);
  grid-auto-flow: column;
}

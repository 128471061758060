.notification-badge {
  align-items: center;
  background: var(--color-primary-100);
  border-radius: 9999px;
  color: var(--color-primary-700);
  display: inline-flex;
  font: var(--font-xxs-regular);
  height: 14px;
  justify-content: center;
  text-align: center;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
}

.notification-badge--disabled {
  background-color: var(--color-grey-300);
  color: var(--color-grey-500);
}

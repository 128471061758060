.figure {
  align-items: center;
  display: flex;
  flex-flow: column;
  text-align: center;
}

.figure__number {
  color: var(--color-grey-800);
  font: var(--font-3xl-medium);
  letter-spacing: var(--font-heading-letter-spacing-h345);
}

.figure__label {
  color: var(--color-grey-700);
  font: var(--font-sm-medium);
  letter-spacing: var(--font-heading-letter-spacing-h345);
  margin-top: var(--spacing-1);
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.figure__label-placeholder {
  display: inline-block;
}

.loading-indicator {
  animation: slide-right 1.7s ease infinite;
  background: var(--color-primary-100) linear-gradient(90deg, var(--color-primary-600) 0%, var(--color-primary-600) 100%);
  background-position: calc(-100% / 3) center;
  background-repeat: no-repeat;
  background-size: 25%;
  height: 3px;
  overflow: hidden;
  width: 100%;
}

@keyframes slide-right {
  0% {
    background-position: calc(-100% / 3) center;
  }

  10% {
    background-position: calc(-100% / 3) center;
  }

  90% {
    background-position: calc(100% + 100% / 3) center;
  }

  100% {
    background-position: calc(100% + 100% / 3) center;
  }
}

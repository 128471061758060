.replace-requests {
  /* noop */
}

.replace-requests__none {
  font: var(--font-base-medium);
  margin: 0;
  padding: var(--spacing-7) var(--spacing-4);
  text-align: center;
}

.filter-popup-quick__wrapper {
  position: relative;
}

.filter-popup-quick {
  margin-top: var(--spacing-2);
  border-radius: var(--spacing-1);
  box-shadow: 0 5px 34px rgba(0, 0, 0, 0.1);
  background: var(--color-bw-50);
  width: 250px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.filter-popup-quick__header {
  align-items: center;
  color: var(--color-grey-900);
  display: flex;
  background-color: var(--color-grey-200);
  font: var(--font-base-medium);
  justify-content: space-between;
  padding: var(--spacing-2);
}

.filter-popup-quick__close-button {
  margin: 0 0 0 var(--spacing-4);
}

.filter-popup-quick__list {
  border-bottom: 1px solid var(--color-grey-200);
  font: var(--font-sm-medium);
  list-style: none;
  margin: 0;
  max-height: 400px;
  overflow: auto;
  padding: var(--spacing-2) var(--spacing-2) 0 var(--spacing-2);
}

/* .filter-popup-quick__list:first-child {
  border-top: 0;
} */

.filter-popup-quick__list-item {
  color: var(--color-grey-800);
  text-align: left;

  /* spacing doesn't align with grid */
  padding: var(--spacing-3) 8px;
  display: flex;
}

.filter-popup-quick__apply-button {
  margin: var(--spacing-2) var(--spacing-3);
}

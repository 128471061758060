.section-shift-rotations__row {
  /* noop */
}

.section-shift-rotations__row--full-width {
  grid-column: span 2;
}

.section-shift-rotations__pill-section {
  display: flex;
  gap: var(--spacing-4);
  flex-wrap: wrap;
}

.section-shift-rotations__pill {
  padding: var(--spacing-2) var(--spacing-4);
}

.hour-balance {
  color: var(--color-grey-700);
  font: var(--font-xs-medium);
  display: flex;
  align-items: center;
  /*
    needed to avoid moving of content when user moves
    between hour account and payment information
   */
  min-height: var(--spacing-1);
}

.hour-balance__hour-account {
  margin: -1em 0 -1em var(--spacing-2);
}

.hour-balance__badge {
  margin-left: var(--spacing-1);
}

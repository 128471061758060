.checkbox-availability {
  display: flex;
}

.checkbox-availability__input {
  clip-path: polygon(0 0);
  height: 0;
  width: 0;
}

.checkbox-availability__label {
  cursor: pointer;
  display: block;
  width: 100%;
}

.checkbox-availability__input:checked + .checkbox-availability__label {
  background: var(--color-grey-100);
}

.checkbox-availability__indicator {
  display: block;
  text-indent: -9999px;
  margin: var(--spacing-3) auto;
}

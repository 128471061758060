.controls-datepicker__popup {
  padding: var(--spacing-3) 0;
  position: absolute;
  top: 100%; /* will be overridden */
  left: 33%; /* will be overridden */
  z-index: 999999;
}

.controls-datepicker__label {
  white-space: nowrap;
  padding: 0 var(--spacing-3);
}

.controls-datepicker {
  --background-color-button: var(--color-grey-100) !important;

  border-radius: 4px;
  transition: background var(--transition-fast);
}

.controls-datepicker:hover,
.controls-datepicker:focus {
  --background-color-button: var(--color-grey-200) !important;
}

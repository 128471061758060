.indicator-join-request {
  /* noop */
}

.indicator-join-request__icon {
  background: var(--color-info-600);
  border: var(--color-bw-50) 1px solid;
  border-radius: 9999px;
  color: var(--color-bw-50);
  height: var(--spacing-4);
  justify-content: center !important;
  width: var(--spacing-4);
}

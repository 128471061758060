.collapsible-qualifications {
  border-bottom: 1px solid var(--color-athens);
}

.collapsible-qualifications__header {
  align-items: center;
  background: var(--color-athens-light);
  color: var(--color-comet);
  display: flex;
  font: var(--font-caption);
  justify-content: space-between;
  padding-left: var(--spacing-4);
}

.collapsible-qualifications__button {
  background: 0;
  border: 0;
  color: var(--color-comet);
  cursor: pointer;
  height: var(--spacing-11);
  outline: 0;
  padding: 0;
  transition: background var(--transition-fast), color var(--transition-fast);
  width: var(--spacing-11);
  text-decoration: none;
}

.collapsible-qualifications__button:focus {
  color: var(--color-shyftplan-green);
}

.collapsible-qualifications__button:hover {
  background: var(--color-comet);
  color: var(--color-white);
}

.collapsible-qualifications__icon {
  justify-content: center !important;
}

.collapsible-qualifications__body {
  transform-origin: top;
  transition: opacity var(--transition-default), max-height var(--transition-default);
  color: var(--color-comet);
  font: var(--font-caption);
  padding: var(--spacing-3) var(--spacing-4);
}

.transition-slide-enter.collapsible-qualifications__body,
.transition-slide-leave-to.collapsible-qualifications__body {
  opacity: 0;
  max-height: 0;
}

.transition-slide-enter-to.collapsible-qualifications__body,
.transition-slide-leave.collapsible-qualifications__body {
  opacity: 1;
  max-height: 1200px;
}

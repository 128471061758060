.section-time-frame-kind {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr max-content
}

.section-time-frame-kind__label {
  color: var(--color-grey-900);
  cursor: pointer;
  font: var(--font-sm-medium);
}

.single {
  /* noop */
}

.single__breadcrumb {
  color: var(--color-grey-900);
  text-decoration: none;
  transition: color var(--transition-fast);
}

.single__breadcrumb:hover,
.single__breadcrumb--active {
  color: var(--color-primary-600);
}

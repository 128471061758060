.input-button-select {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  padding: var(--spacing-4) 0;
}

.input-button-select__label {
  background: 0;
  display: flex;
  align-items: center;
  border: var(--color-grey-300) 1px solid;
  flex: 1;
  font: var(--font-sm-regular);
  outline: 0;
  padding: 0 var(--spacing-4);
  height: 40px;
  justify-content: center;
  transition: background var(--transition-fast), color var(--transition-fast);
}

.input-button-select__label .input-button-select__icon + .input-button-select__label-text {
  margin-left: var(--spacing-2);
}

.input-button-select__label:not(.input-button-select__label--disabled) {
  cursor: pointer;
}

.input-button-select__label:not(.input-button-select__label--disabled):not(.input-button-select__label--selected):hover {
  background: var(--color-grey-50);
}

.input-button-select__label:first-child {
  border-radius: var(--spacing-2) 0 0 var(--spacing-2);
}

.input-button-select__label:last-child {
  border-radius: 0 var(--spacing-2) var(--spacing-2) 0;
}

.input-button-select__label:not(:first-child) {
  margin-left: -1px;
}

.input-button-select__label--disabled {
  opacity: 0.6;
}

.input-button-select__label--selected {
  background: var(--color-primary-100);
  color: var(--color-primary-600);
  border-color: var(--color-primary-600);
  position: relative;
  z-index: 1;
}

.input-button-select__input {
  /* Hide the input without making it invisible to screenreaders */
  clip-path: polygon(0 0);
  width: 0;
}

.shifts-list {
  background-color: var(--color-bw-50);
  color: var(--color-grey-900);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: var(--font-base-regular);
  padding: var(--spacing-1) var(--spacing-2);
  border-bottom: 1px solid var(--color-grey-100);
}

.shifts-list--shift_details {
  flex: 1;
}

.shifts-list--datetime {
  font-weight: 500;
}

.shifts-list--position_employment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-1);
  font: var(--font-sm-medium);
}

.shifts-list--position {
  flex: 0 1 50%;
}

.shifts-list--employment {
  flex: 0 1 50%;
}

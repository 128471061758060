.general-setup {
  /* noop */
}

.general-setup__form {
  align-content: center;
  display: grid;
  flex: 1;
  gap: var(--spacing-15) 0;
  margin: var(--spacing-10) auto;
  /* 378px */
  max-width: 23.625rem;
  width: calc(100% - var(--spacing-7));
}

.general-setup__form-input {
  --background: var(--color-bw-50);

  justify-content: center;
  text-align: center;
}

/* exception, otherwise calendar picker will be misaligned in Chrome */
.general-setup__form-input input[type='date'] {
  flex: initial;
  position: relative;
}

/* exception, otherwise calendar picker will be misaligned in Chrome */
.general-setup__form-input input[type='date']::-webkit-calendar-picker-indicator {
  left: calc(-1 * var(--spacing-10));
}

.general-setup__button-icon {
  height: 1em;
  justify-content: flex-end !important;
  width: var(--spacing-10);
}

.general-setup__static-input {
  background: var(--color-bw-50);
  border-bottom: var(--color-grey-700) 1px solid;
  color: var(--color-grey-900);
  cursor: default;
  font: var(--font-xl-medium);
  justify-content: center;
  letter-spacing: var(--font-heading-letter-spacing-h345);
  opacity: 0.6;
  padding: 0 0 var(--spacing-2);
  text-align: center;
}

.general-setup__static-input-label {
  font: var(--font-sm-medium);
  line-height: 1.5;
  margin-bottom: var(--spacing-2);
  text-align: center;
}

.general-setup__static-input-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.general-setup__avatar {
  margin: 0 var(--spacing-2) 0 0;
}

.general-setup__spinner {
  font-size: 1.3125rem;
}

.general-setup__conflict-list {
  list-style: disc inside;
  margin: var(--spacing-8) 0 0;
  padding: 0;
}

.general-setup__conflict-list-item {
  font: var(--font-sm-medium);
  font-weight: 700;
}

.modal__error-list {
  color: var(--color-error-700);
  font: var(--font-sm-medium);
}

.section-location-positions {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: var(--spacing-3) 0 0;
}

.section-location-positions__item {
  flex: 1 1 33%;
}

.section-location-positions__toggle-container {
  display: flex;
  place-content: flex-end;
}

.section-location-positions__toggle-all {
  place-content: end;
}

.filter-box-assignment-group__group {
  display: flex;
  flex-flow: column nowrap;
}

.filter-box-assignment-group__stats {
  color: #adadad;

  /* 12px */
  font-size: 0.75rem;
  font-weight: 400;
  margin-top: var(--spacing-1);
}

.form {
  flex: 1;
  grid-template-columns: initial;
  max-width: initial;
}

.form__pill-select {
  margin: 0 var(--spacing-4);
  width: initial;
}

.form__section-input {
  /* noop */
}

.form__section-input--full-width {
  grid-column: 1 / -1;
}

.form__centered-button {
  margin: 0 auto;
}

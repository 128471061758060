.additional-qualification {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-3) var(--spacing-6);
  grid-column: 1 / -1;
}

.additional-qualification__input {
  flex: 1;
  grid-template-columns: 1fr;
  min-width: 14ch;
  overflow: hidden;
  position: relative;
}

.additional-qualification__select {
  flex: 1;
  grid-template-columns: 1fr;
  min-width: 14ch;
  overflow: hidden;
  position: relative;
}

.additional-qualification__input > div {
  display: flex;
  width: 100%;
}

.additional-qualification__button-delete {
  align-self: end;
}

.attachment-upload {
  align-items: center;
  border-radius: var(--spacing-2);
  border: var(--color-grey-300) 1px dashed;
  color: var(--color-grey-700);
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  justify-content: center;
  padding: var(--spacing-6);
  transition: background var(--transition-default), color var(--transition-default);
}

.attachment-upload:hover {
  background: var(--color-grey-50);
  cursor: pointer;
}

.attachment-upload--invalid {
  border-color: var(--color-error-300);
}

.attachment-upload__input {
  display: none;
}

.attachment-upload__icon {
  color: var(--color-grey-500);
  height: var(--spacing-11);
  justify-content: center !important;
  width: var(--spacing-11);
}

.attachment-upload__text {
  font: var(--font-lg-medium);
  margin-top: var(--spacing-3);
}

.attachment-upload__clear-button {
  margin: 0 0 0 var(--spacing-5);
}

.attachment-upload__error {
  color: var(--color-error-700);
}

.icon-state {
  border-radius: 9999px;
  color: var(--color-bw-50);
  height: var(--spacing-8);
  justify-content: center !important;
  width: var(--spacing-8);
}

.icon-state--green {
  background: var(--color-primary-600);
}

.icon-state--orange {
  background: var(--color-warning-700);
}

.subnavigation {
  /* noop */
}

.subnavigation__list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.subnavigation__list-item {
  font: var(--font-xl-medium);
  letter-spacing: var(--font-heading-letter-spacing-h345);
  margin: 0 0 0 var(--spacing-7);
}

.subnavigation__list-item:not(:last-child) {
  border-bottom: var(--color-grey-200) 1px solid;
}

.subnavigation__button-enabled {
  color: var(--cod-gray);
  opacity: 1;
}

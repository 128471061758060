.section-positions {
  /* noop */
}

.section-positions__list {
  list-style: none;
  margin: 0;
  padding: 0;
  grid-column: span 2;
}

.section-positions__item {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--spacing-4);
}

.section-positions__checkbox {
  height: 100%;
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: center;
}

.section-positions__item {
  display: grid;
  grid-template-columns: 1fr 15ch;
}

.section-positions__item:not(:last-child) {
  border-bottom: var(--color-grey-200) 1px solid;
  padding: var(--spacing-2) 0;
}

.section-positions__checkbox label {
  font: var(--font-base-regular);
  flex-flow: row;
}

.section-positions__input label {
  justify-content: flex-end;
  align-items: center;
}

.section-positions__input {
  padding: var(--spacing-2) 0;
}

.section-positions__input input {
  text-align: right;
}

.section-positions__alert {
  margin: 0 var(--spacing-4);
}

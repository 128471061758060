.rotation-groups {
  /* noop */
}

.rotation-groups__title {
  color: var(--color-grey-900);
  font: var(--font-lg-medium);
  margin: 0 0 var(--spacing-6);
}

.rotation-groups__cards {
  display: grid;
  grid-gap: var(--spacing-14);
  grid-template-columns: repeat(3, 1fr);
}

.rotation-groups__card {
  overflow: hidden;
  background-color: var(--color-bw-50);
}

.rotation-groups__button-wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: var(--spacing-3);
}

.rotation-groups__button {
  align-items: center;
  background: 0;
  border: 0;
  color: var(--color-grey-800);
  cursor: pointer;
  display: flex;
  font: var(--font-xl-medium);
  height: 100%;
  letter-spacing: var(--font-heading-letter-spacing-h345);
  outline: 0;
  padding: var(--spacing-6);
  text-decoration: none;
  transition: background var(--transition-fast), color var(--transition-fast);
  width: 100%;
}

.rotation-groups__button:focus,
.rotation-groups__button:hover {
  background: var(--color-primary-100);
  color: var(--color-primary-600);
}

.rotation-groups__button-icon {
  --icon-font-size-lg: var(--spacing-6);

  background: var(--color-grey-100);
  height: var(--spacing-14);
  justify-content: center !important;
  width: var(--spacing-14);
  margin: 0 var(--spacing-5) 0 0;
  transition: background var(--transition-fast), color var(--transition-fast);
}

.rotation-groups__button:focus .rotation-groups__button-icon,
.rotation-groups__button:hover .rotation-groups__button-icon {
  background: var(--color-primary-600);
  color: var(--color-bw-50);
}

.rotation-groups__spinner {
  align-self: center;
  justify-self: center;
}

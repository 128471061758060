.navigation {
  --navigation-width: 64px;

  width: var(--navigation-width);
  background: var(--color-grey-700);
  display: flex;
  flex-flow: column nowrap;
}

.navigation__brand-icon,
.navigation__brand-full {
  padding: var(--spacing-2);
  width: 100%;
}

.navigation__brand-full {
  display: none;
}

@media (min-width: 1920px) {
  .navigation {
    --navigation-width: 280px;
  }

  .navigation__list-item {
    display: flex;
    place-content: center center;
  }

  .navigation__brand-icon {
    display: none;
  }

  .navigation__brand-full {
    display: inherit;
    width: 160px;
    margin-right: 86px;
  }
}

.navigation__list, .navigation__button-support-container {
  list-style: none;
  margin: 0;
  padding: 0 var(--spacing-3);
}

@media (max-width: 767px) {
  .navigation__list {
    display: flex;
  }
}

.navigation__list-item {
  flex: 1;
  margin: var(--spacing-2) 0;
}

.navigation__button-support {
  background: 0;
  border: 0;
  cursor: pointer;
  margin-top: auto;
  outline: 0;
}

@media (max-width: 767px) {
  .navigation__button-support {
    display: none;
  }
}

.navigation__button-support-container {
  display: flex;
  place-content: center center;
}

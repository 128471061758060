.cancel-offer {
  font: var(--font-base-medium);
  text-align: center;
  padding: var(--spacing-7) var(--spacing-4);
}

.cancel-offer__message {
  color: var(--color-grey-900);
  font: var(--font-base-medium);
  margin: 0;
  padding: var(--spacing-7) 0;
  text-align: center;
}

.cancel-offer__button {
  margin: var(--spacing-7) auto 0;
}

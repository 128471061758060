.checkbox-general {
  display: flex;
}

.checkbox-general__input {
  clip-path: polygon(0 0);
  height: 0;
  width: 0;
}

.checkbox-general__input:focus + .checkbox-general__label .checkbox-general__icon {
  background: var(--color-grey-100);
  box-shadow: 0 0 0 var(--spacing-2) var(--color-grey-100);
}

.checkbox-general__label {
  cursor: pointer;
  display: block;
  transition: color var(--transition-fast);
  width: 100%;
}

.checkbox-general__input:checked + .checkbox-general__label {
  color: var(--color-primary-600);
}

.checkbox-general__tooltip {
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-1);
  justify-content: center;
}

.checkbox-general__icon {
  border-radius: 9999px;
  box-shadow: 0 0 0 var(--spacing-2) transparent;
  display: block;
  overflow: hidden;
  text-align: center;
  transition: background var(--transition-fast), box-shadow var(--transition-fast);
}

.checkbox-general__other {
  font: var(--font-sm-medium);
}

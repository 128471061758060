.name-edit-form {
  --grid-gap: var(--spacing-2);

  width: 100%;
  height: 100%;
  padding: var(--spacing-4) var(--spacing-7) var(--spacing-4) var(--spacing-5);
  align-items: center;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(max-content, 1fr) minmax(var(--spacing-7), 1fr);
  justify-items: center;
}

.name-edit-form__form {
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  gap: var(--grid-gap);
  height: 100%;
  width: 100%;
  transition: border 0.2s ease, width 0.2s ease;
  border-bottom: 1.5px var(--color-primary-600) solid;
}

.name-edit-form__form:focus-within {
  border-bottom: 1.5px var(--color-primary-600) solid;
}

.name-edit-form__input {
  height: 100%;
  background: none;
  border-style: none;
  flex: 1;
  transition: color var(--transition-default);
}

.name-edit-form__input:focus,
.name-edit-form__input:hover {
  color: var(--color-primary-600);
}

.name-edit-form__input--invalid,
.name-edit-form__input--invalid:focus,
.name-edit-form__input--invalid:hover {
  color: var(--color-error-700);
}

.name-edit-form__error {
  color: var(--color-error-700);
  font: var(--font-sm-medium);
  grid-column: 3 / 4;
  justify-self: left;
  white-space: pre;
}

/* enter/leave animation */
:global(.accordion-header-transition-enter) .name-edit-form__form {
  width: calc(300% - 6 * var(--grid-gap));
  border-bottom: 1.5px transparent solid;
}

:global(.accordion-header-transition-leave-to) .name-edit-form__form {
  width: calc(300% - 6 * var(--grid-gap));
  border-bottom: 1.5px transparent solid;
}

/* hide error during animation */
:global(.accordion-header-transition-enter-active) .name-edit-form__error,
:global(.accordion-header-transition-leave-active) .name-edit-form__error {
  visibility: hidden;
}

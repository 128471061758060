.indicator-conflict {
  /* noop */
}

.indicator-conflict__icon {
  color: var(--color-error-700);
  height: var(--spacing-4);
  justify-content: center !important;
  width: var(--spacing-4);
}

.input-repeat-dates {
  gap: var(--spacing-4);
  display: grid;
}

.input-repeat-dates__label {
  color: var(--color-grey-900);
  font: var(--font-sm-medium);
  margin-bottom: var(--spacing-2);
}

.input-repeat-dates__options {
  justify-self: center;
  width: max-content;
}

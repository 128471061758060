.header__dropdown {
  border-radius: 9999px;
  font: var(--font-base-regular);
  padding: var(--spacing-2) var(--spacing-5);
  transition: background var(--transition-timing-function-default) calc(var(--transition-duration-default) / 2);
}

.header__dropdown:hover {
  background: var(--color-grey-200);
}

.header__dropdown > button {
  line-height: 1.3;
}

.absences__table {
  --header-cell-background: var(--color-bw-50);

  margin: 0 var(--spacing-7);
}

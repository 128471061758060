.manager-view {
  display: grid;
  gap: var(--spacing-4);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;
  overflow: hidden;
  padding: calc(var(--spacing-1) / 2);
}

.manager-view__column {
  display: flex;
  flex-flow: column nowrap;
  border-radius: var(--spacing-1);
  box-shadow: var(--shadow-base);
}

.manager-view__column:not(:last-child) {
  border-right: var(--color-grey-100) 1px solid;
}

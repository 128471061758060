.rotation-group-card {
  --card-border-radius: var(--spacing-4) !important;
  --card-outer-shadow-color: transparent !important;
  background-color: var(--color-bw-50);

  padding: var(--spacing-4);
}

.rotation-group-card {
  margin: var(--spacing-3) 0 var(--spacing-5);
}

.rotation-group-card__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 var(--spacing-4);
}

.rotation-group-card__title {
  font: var(--font-base-regular);
  color: var(--outer-space);
  margin: 0;
}

.rotation-group-card__actions {
  display: grid;
  grid-auto-flow: column;
  gap: var(--spacing-2);
}

.rotation-group-card__icon-button {
  --button-icon-text-color: var(--color-grey-900) !important;

  height: var(--spacing-8);
  justify-content: center !important;
  width: var(--spacing-8);
}

.rotation-group-card__main {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.rotation-group-card__users-number {
  align-items: center;
  color: var(--outer-space);
  display: flex;
  font: var(--font-sm-medium);
}

.rotation-group-card__user-icon {
  height: var(--spacing-7);
  justify-content: center !important;
  margin: 0 var(--spacing-1) 0 0;
  width: var(--spacing-7);
}

.rotation-group-card__button {
  transition: opacity var(--transition-default);
}

.rotation-group-card__button--hidden {
  opacity: 0 !important;
}

.collisions {
  list-style: none;
  margin: 0;
  padding: 0;
}

.collisions__item {
  margin: 0;
  padding: 0;
}

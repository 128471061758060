.breadcrumbs__list {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumbs__list-item {
  margin-right: var(--spacing-2);
  text-decoration: none;
}

.breadcrumbs__list-item--large {
  margin-right: var(--spacing-4);
  text-decoration: none;
  font: var(--font-3xl-regular);
}

.breadcrumbs__list-item * {
  color: var(--color-grey-500);
  text-decoration: none;
}

.breadcrumbs__list-item--selected * {
  color: var(--color-grey-700);
}

.breadcrumbs__list-item:hover * {
  color: var(--color-grey-600);
  transition: color var(--transition-fast);
}

.breadcrumbs__list-item:not(.breadcrumbs__list-item--large) {
  font: var(--font-sm-regular);
}

.breadcrumbs__list-item--icon {
  margin-right: var(--spacing-2);
  color: var(--color-grey-500);
}

.calendar-print-controls__print {
  background: var(--color-grey-100);
  transition: background var(--transition-fast);
  min-height: var(--spacing-10);
  min-width: var(--spacing-13);
  color: var(--color-grey-700);
  box-shadow: none;
  cursor: pointer;
}

.calendar-print-controls__print:disabled {
  opacity: 0.6;
  cursor: initial;
}

.calendar-print-controls__print:hover,
.calendar-print-controls__print:focus {
  background: var(--color-grey-200);
  color: var(--color-grey-700);
}

.calendar-print-controls__tooltip-text {
  text-align: center;
}

.radio-button-group {
  --radio-button-border-radius: 4px;

  display: flex;
}

.radio-button-group__button {
  background: var(--color-grey-100);
  border: 0;
  border-radius: 0;
  margin: 0;
  min-height: var(--spacing-10);
  min-width: var(--spacing-13);
  outline: none;
  padding: var(--spacing-1) var(--spacing-3);
  transition: background var(--transition-fast);
}

.radio-button-group__button:hover {
  background: var(--color-grey-200);
}

.radio-button-group__tooltip {
  display: inline-block;
}

.radio-button-group__tooltip-text {
  text-align: center;
  display: inline-block;
}

.radio-button-group__tooltip:first-child .radio-button-group__button {
  border-radius: var(--radio-button-border-radius) 0 0 var(--radio-button-border-radius);
}

.radio-button-group__tooltip:last-child .radio-button-group__button {
  border-radius: 0 var(--radio-button-border-radius) var(--radio-button-border-radius) 0;
}

.radio-button-group__tooltip:only-child .radio-button-group__button {
  border-radius: var(--radio-button-border-radius);
}

.radio-button-group__button:not(:disabled) {
  cursor: pointer;
}

.radio-button-group__button--selected {
  background: var(--color-primary-600);
}

.radio-button-group__button--selected:hover {
  background: var(--color-primary-600);
}

.radio-button-group__button-icon {
  color: var(--color-grey-700);
  font-size: var(--spacing-5);
  transition: color var(--transition-fast);
  width: var(--spacing-7);
  height: var(--spacing-7);
  line-height: var(--spacing-7);
}

.radio-button-group__button--selected .radio-button-group__button-icon {
  color: var(--color-bw-50);
}

.filtering {
  display: flex;
}

.filtering__qualifications {
  list-style-type: none;
  padding: var(--spacing-2);
  margin: 0;
}

.filtering__checkbox {
  width: 100%;
  margin: var(--spacing-3) 0;
}

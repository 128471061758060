.employees {
  display: grid;
  grid-gap: var(--spacing-7) var(--spacing-10);
  grid-template-columns: repeat(auto-fit, 1fr);
  padding: var(--spacing-7) var(--spacing-4);
}

.employees__item {
  overflow: hidden;
}

.employees__none {
  font: var(--font-base-medium);
  margin: 0;
  text-align: center;
}

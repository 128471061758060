.input-text {
  /* noop */
  --background: transparent;

  display: inline-flex;
  flex-flow: column nowrap;
  font-family: var(--font-family);
  overflow: initial;
}

.input-text__inner {
  background-color: var(--color-bw-50);
  display: flex;
  flex-flow: column nowrap;
  height: 2.5rem;
  justify-content: inherit;
  transition: background var(--transition-default), border var(--transition-default);
  position: relative;
  text-align: inherit;
  width: 100%;
  padding: 0 var(--spacing-3);
  border: 1px solid var(--color-grey-300);
  border-radius: 4px;
}

.input-text__inner:focus-within {
  border-color: var(--color-primary-500);
  box-shadow: var(--shadow-base);
}

.input-text--disabled .input-text__inner {
  background-color: var(--color-grey-100);
}

.input-text--valid .input-text__inner {
  border-color: var(--color-success-300);
}

.input-text--invalid .input-text__inner {
  border-color: var(--color-error-300);
}

.input-text--invalid .input-text__inner:focus-within {
  border-color: var(--color-error-700);
}

.input-text--valid .input-text__inner:focus-within {
  border-color: var(--color-success-700);
}

.input-text:not(.input-text--disabled) .input-text__inner:hover {
  background-color: var(--color-grey-50);
}

.input-text:not(.input-text--disabled) .input-text__inner:focus-within::after {
  transform: scaleX(1);
}

.input-text__input-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
}

.input-text__input {
  background: transparent;
  border: 0;
  flex: 1;
  height: 100%;
  min-width: 0;
  outline: 0;
  padding: 0;
  text-align: inherit;
  transition: color var(--transition-default);
  font: var(--font-base-regular);
  color: var(--color-grey-900);
  width: 100%;
}

/* move the calendar picker over our custom icon and make it invisible to
   maintain accessibility of the picker */
.input-text__input::-webkit-calendar-picker-indicator {
  height: var(--spacing-10);
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: calc(50% - var(--spacing-5));
  width: var(--spacing-10);
}

.input-text__input:focus {
  background: transparent;
}

.input-text__input::placeholder {
  color: var(--color-grey-500);
  opacity: 0.5;
  transition: color var(--transition-default);
  font: var(--font-base-regular);
}

.input-text__input--with-stepper::-webkit-inner-spin-button {
  display: none;
}

.input-text__input--with-stepper[type="number"] {
  appearance: textfield;
  max-width: 25%;
}

.input-text__input[type="date"] {
  font: var(--font-base-regular);
}

.input-text__label {
  align-items: center;
  color: var(--color-grey-700);
  cursor: pointer;
  display: flex;
  font: var(--font-sm-medium);
  height: var(--spacing-6);
  justify-content: inherit;
}

.input-text__stepper {
  display: flex;
  flex: 1;
}

.input-text__stepper:first-child {
  justify-content: flex-end;
}

.input-text__stepper:last-child {
  justify-content: flex-start;
}

.input-text__stepper-button {
  width: var(--spacing-8);
  height: var(--spacing-8);
}

.input-text__calendar-icon {
  padding-right: var(--spacing-2);
}

.input-text__stepper-button,
.input-text__calendar-icon {
  background: transparent;
  border: 0;
  border-radius: 9999px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  transition: color var(--transition-default);
}

.input-text__stepper-button:disabled,
.input-text--disabled .input-text__calendar-icon {
  color: var(--color-grey-700);
  cursor: initial;
  opacity: 0.6;
}

.input-text__stepper-button:focus,
.input-text__stepper-button:not(:disabled):hover,
.input-text__calendar-icon:focus,
.input-text__calendar-icon:hover {
  background: var(--color-grey-200);
}

.input-text--disabled .input-text__calendar-icon:focus,
.input-text--disabled .input-text__calendar-icon:hover {
  background: 0;
  cursor: initial;
}

.input-text--calendar .input-text__input-wrapper {
  justify-content: center;
  position: relative;
}

.input-text--calendar .input-text__input {
  appearance: none;
}

.input-text--invalid .input-text__error {
  color: var(--color-error-700);
  font: var(--font-sm-regular);
  margin-top: var(--spacing-1);
}

.input-text__hint-text {
  color: var(--color-grey-500);
  margin-left: var(--spacing-1);
  font: var(--font-sm-regular);
}

.input-text__helper-text {
  color: var(--color-grey-500);
  font: var(--font-sm-regular);
  margin-top: var(--spacing-2);
}

.input-text--invalid .input-text__helper-text {
  color: var(--color-error-700);
}

.input-text__icon {
  fill: var(--color-grey-400);
}

.input-text__left-icon {
  margin-right: var(--spacing-2);
}

.input-text__valid-icon {
  fill: var(--color-success-500);
}

.input-text__invalid-icon {
  fill: var(--color-error-500);
}

.input-text__info-icon {
  fill: var(--color-grey-400);
  transition: all var(--transition-default);
}

.input-text--invalid .input-text__info-icon,
.input-text--valid .input-text__info-icon {
  margin-right: var(--spacing-1);
}

.input-text:not(.input-text--disabled) .input-text__inner:hover .input-text__info-icon {
  fill: var(--color-grey-700);
}

.input-text__trailing-text {
  color: var(--color-grey-500);
  font: var(--font-base-regular);
  margin-right: var(--spacing-1);
}

.input-text__input:hover {
  color: var(--color-grey-700);
}

.dialog {
  min-height: var(--dialog-min-height);
  max-height: 100%;
}

@media (max-height: 968px) {
  .dialog {
    min-height: 100%;
  }
}

@media (min-width: 767px) {
  .dialog {
    min-width: var(--dialog-max-width);
  }
}

.dialog__body {
  display: flex;
  flex-flow: column nowrap;
  padding: var(--spacing-6) 0;
}

.dialog__footer {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-3);
  background-color: var(--color-grey-100);
  padding: var(--spacing-6);
  position: sticky;
  bottom: 0;
}

.dialog__footer-start,
.dialog__footer-end {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-3);
}

.dialog__footer-start {
  justify-content: flex-start;
}

.dialog__footer-end {
  justify-content: flex-end;
}

.dialog__spinner {
  margin: 0 auto;
  font-size: 2em;
}

.dialog__error {
  color: var(--color-error-700);
  background-color: var(--color-error-50);
  max-width: var(--dialog-max-width-sm);
  text-align: center;
  width: 100vw;
}

.placeholder {
  --placeholder-end-background-color: rgba(217, 218, 224, 0.4);
  --placeholder-start-background-color: rgba(221, 221, 221, 0.7);
  --transition-background-duration: 1.5s;
  --placeholder-height: calc(1em * var(--font-line-text-height));
  --placeholder-width: 5ch;

  animation: pulse var(--transition-background-duration) infinite;
  background: linear-gradient(270deg, var(--placeholder-start-background-color), var(--placeholder-end-background-color), var(--placeholder-start-background-color)) 0% 0% / 300% 300%;
  border-radius: 99999px;
  height: var(--placeholder-height);
  width: var(--placeholder-width);
}

@keyframes pulse {
  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.weeks-grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto repeat(7, 1fr);
  gap: var(--spacing-7);
  align-items: center;
  justify-items: center;
  margin-bottom: var(--spacing-7);
}

.weeks-grid__header {
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.weeks-grid__header > :first-child {
  grid-column-start: 2;
}

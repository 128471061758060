.rotation-pattern-setup__content {
  flex: 1;
}

.rotation-pattern-setup__content--center {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: var(--spacing-12) 0;
}

.rotation-pattern-setup__weeks-grid {
  width: 100%;
}

.rotation-pattern-setup__alert {
  margin: var(--spacing-9) auto;
}

.filter-button {
  position: relative;
}

.filter-button__popup {
  background-color: var(--color-bw-50);
  display: flex;
  min-width: min-content;
  max-width: 300px;
  position: absolute;
  left: 0;
  top: calc(100% + var(--table-vertical-gap));
  width: 100%;
  z-index: 9999;
}

.filter-button__popup--right-align {
  left: initial;
  right: 0;
}

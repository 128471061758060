.column__header {
  align-items: center;
  background: var(--color-grey-100);
  color: var(--color-grey-700);
  display: flex;
  font: var(--font-sm-medium);
  justify-content: space-between;
  padding: var(--spacing-3);
}

.column__body {
  /* 8px is also the default for Firefox, the only browser supporting scrollbar-width */
  --scrollbar-width: 8px;

  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

.column__body::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.column__body:hover {
  scrollbar-color: var(--color-grey-200) transparent;
}

.column__body:hover::-webkit-scrollbar-thumb {
  background: var(--color-grey-200);
  border-radius: var(--spacing-1);
}

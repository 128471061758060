.icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  width: var(--icon-item-width);
  height: var(--icon-item-height);
}

.icon__item--size-12 {
  --icon-item-width: 0.75rem;
  --icon-item-height: 0.75rem;
}

.icon__item--size-16 {
  --icon-item-width: 1rem;
  --icon-item-height: 1rem;
}

.icon__item--size-20 {
  --icon-item-width: 1.25rem;
  --icon-item-height: 1.25rem;
}

.icon__item--size-24 {
  --icon-item-width: 1.5rem;
  --icon-item-height: 1.5rem;
}

.cell-state {
  /* so the tooltip is visible */
  overflow: initial;

  /* align dropdown menu with cell not with itself */
  position: relative;
}

.cell-state__spinner {
  display: inline-block;
}

.filter-box {
  background: var(--color-bw-50);
  border-radius: var(--spacing-1);
  margin-bottom: var(--spacing-5);
  overflow: hidden;
  position: relative;
  box-shadow: var(--shadow-base);
}

.filter-box__header {
  align-items: center;
  background: var(--color-grey-100);
  color: var(--color-grey-700);
  display: flex;
  font: var(--font-sm-medium);
  padding: 0 var(--spacing-3);
  height: 40px;
}

.filter-box__label {
  flex: 1;
  margin: 0 var(--spacing-4) 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filter-box__controls {
  display: flex;
  gap: var(--spacing-2);
}

.filter-box__button {
  background: 0;
  border: 0;
  color: var(--color-grey-700);
  cursor: pointer;
  height: var(--spacing-6);
  outline: 0;
  padding: 0;
  border-radius: calc(var(--spacing-1) / 2);
  transition: background var(--transition-fast), color var(--transition-fast);
  width: var(--spacing-6);
  text-decoration: none;
  text-align: center;
}

.filter-box__button:focus {
  color: var(--color-primary-600);
}

.filter-box__button:hover {
  background: var(--color-grey-700);
  color: var(--color-bw-50);
}

.filter-box__icon {
  justify-content: center !important;
  height: 100%;
}

.filter-box__body {
  transform-origin: top;
  transition: opacity var(--transition-default), max-height var(--transition-default);
}

.transition-slide-enter.filter-box__body,
.transition-slide-leave-to.filter-box__body {
  opacity: 0;
  max-height: 0;
}

.transition-slide-enter-to.filter-box__body,
.transition-slide-leave.filter-box__body {
  opacity: 1;
  max-height: 1200px;
}

.rotation-group__options {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
}

.rotation-group__option {
  color: var(--color-outer-space);
  text-align: left;

  /* spacing doesn't align with grid */
  padding: var(--spacing-3) 8px;
  display: flex;
}

.rotation-group--info-panel {
  margin-top: var(--spacing-1);
}

.position-dot {
  border-radius: 9999px;
  display: inline-block;
  height: 8px;
  margin: 2px;
  overflow: hidden;
  padding: 0;
  text-indent: -10000px;
  width: 8px;
}

.filter-box-employment__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.filter-box-employment__top-bar {
  display: flex;
  border-bottom: 1px solid #f3f3f3;
}

.filter-box-employment__button {
  align-items: center;
  background: 0;
  border: 0;
  color: #898989;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 14px;
  justify-content: center;
  padding: 10px 0;
}

.filter-box-employment__button--active {
  color: var(--color-primary-600);
}

.filter-box-employment__icon {
  margin: 0 0.25ch 0 0;
}

.filter-box-employment__search {
  border-bottom: #f3f3f3 0 solid;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.35s ease, border-bottom-width;
  transition-delay: 0s, 0.35s;
}

.filter-box-employment__search--expanded {
  /* 60px */
  max-height: 3.75rem;
  border-bottom-width: 1px;
  transition-delay: 0s;
}

.filter-box-employment__input {
  appearance: none;
  border: #f6f6f6 1px solid;
  border-radius: 9999px;
  color: #adadad;
  font-size: 14px;
  line-height: 1.428571429;
  outline: 0;
  padding: 4px 12px 4px 30px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
}

.filter-box-employment__input:focus {
  border-color: var(--shyftplan-green);
}

.filter-box-employment__label {
  display: block;
  margin: 10px;
  position: relative;
}

.filter-box-employment__label-icon {
  color: #adadad;
  font-size: 18px;
  left: 0;
  padding: 6px 8px 0;
  position: absolute;
  top: 0;
}

.filter-box-employment__pagination {
  border-top: rgba(0,0,0,0.1) 1px solid;
}

.filter-box-employment__draggable:global(.draggable--handle) {
  max-width: 300px;
  border: 1px solid var(--color-grey-200);
  opacity: 1;
}

.column-assigned__header {
  align-items: center;
  background: var(--color-grey-100);
  color: var(--color-grey-700);
  display: flex;
  font: var(--font-sm-medium);
  justify-content: space-between;
  padding: var(--spacing-2) var(--spacing-4);
}

.column-assigned__leave-request-filter {
  --color-checked: var(--color-warning-700);
}

.column-assigned__body {
  /* 8px is also the default for Firefox, the only browser supporting scrollbar-width */
  --scrollbar-width: var(--spacing-2);

  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

.column-assigned__body::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.column-assigned__body:hover {
  scrollbar-color: var(--color-grey-200) transparent;
}

.column-assigned__body:hover::-webkit-scrollbar-thumb {
  background: var(--color-grey-200);
  border-radius: var(--spacing-1);
}

.column-assigned__item-container {
  overflow: hidden;
  padding: var(--spacing-1) var(--spacing-2);
  position: relative;
  transition: background var(--transition-fast), opacity var(--transition-fast);
  display: flex;
  justify-content: space-between;
  min-height: 54px; /* to make sure both columns in row height same */
  align-content: center;
}

.column-assigned__item-container:not(:last-child) {
  border-bottom: var(--color-grey-100) 1px solid;
}

.column-assigned__item-container:hover {
  background: #f7f8fa80;
}

.column-assigned__item {
  position: relative;
  width: calc(100% - 30px); /* 100 - action icon button */
  display: flex;
  align-items: center;
}

.column-assigned__employment-avatar {
  padding-right: var(--spacing-1);
}

.column-assigned__unassign-button {
  background-color: var(--color-error-100);
  color: var(--color-error-700);
  outline: none;
}

.column-assigned__unassign-button:focus,
.column-assigned__unassign-button:hover {
  background-color: var(--color-error-200);
}

.column-assigned__tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column-assigned__icon {
  color: var(--color-grey-700);
  transition: color var(--transition-fast);
}

.column-assigned__leave-shift-indicator {
  position: absolute;
  left: var(--spacing-7);
  top: calc((-1) * var(--spacing-1));
}

.column-assigned__loading-indicator {
  position: sticky;
  z-index: 9999;
  top: 0;
}

.column-assigned__loading-indicator:not(:last-child) {
  margin-bottom: calc((-1) * var(--spacing-1));
}

.column-assigned__assignment-group-unassign-dialog-body {
  max-width: calc(13 * var(--spacing-16));
}

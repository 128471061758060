.dialog-shiftplan-notifications__inner {
  background: var(--color-bw-50);
  min-height: 20vh;
  margin: calc(-1 * var(--spacing-7)) calc(-1 * var(--spacing-4));
  width: var(--dialog-max-width-sm);
}

.dialog-shiftplan-notifications__tab {
  position: relative;
}

.router-link-icon {
  align-items: center;
  border-radius: 4px;
  color: var(--color-primary-600);
  display: inline-flex;
  justify-content: center;
  outline: transparent 1px solid;
  transition: background var(--transition-fast), outline var(--transition-fast);
  text-decoration: none;
}

.router-link-icon:focus {
  background: var(--color-primary-100);
  outline-color: var(--color-primary-100);
}

.router-link-icon:hover {
  background: var(--color-primary-100);
}

.router-link-icon--xxsmall {
  height: 24px;
  width: 24px;
}

.router-link-icon--xsmall {
  height: 32px;
  width: 32px;
}

.router-link-icon--small {
  height: 36px;
  width: 36px;
}

.router-link-icon--medium {
  height: 40px;
  width: 40px;
}

.router-link-icon--large {
  height: 48px;
  width: 48px;
}

.router-link-icon--xlarge {
  height: 56px;
  width: 56px;
}

.rotation-group-card {
  display: flex;
  flex-flow: column;
  padding: var(--spacing-6);
  background-color: var(--color-bw-50);
}

.rotation-group-card__actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-2);
}

.rotation-group-card__button {
  align-self: flex-end;
  padding: 0;
  margin: 0 0 var(--spacing-2) var(--spacing-2);
}

.rotation-group-card__button ul {
  max-width: max-content;
}

.rotation-group-card__title {
  color: var(--color-grey-900);
  font: var(--font-2xl-medium);
  margin: 0;
}

.rotation-group-card__subtitle {
  color: var(--color-grey-700);
  font: var(--font-sm-medium);
  margin: 0 0 var(--spacing-5);
}

.rotation-group-card__list {
  color: var(--color-grey-800);
  display: grid;
  font: var(--font-sm-medium);
  grid-auto-flow: row;
  grid-gap: var(--spacing-2);
  list-style: none;
  margin: 0;
  padding: 0;
}

.rotation-group-card__list li {
  align-items: center;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: var(--spacing-1);
}

.rotation-group-card__icon {
  justify-content: center !important;
  height: var(--spacing-7);
  width: var(--spacing-7);
}

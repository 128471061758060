:root {
  /* Font families. */
  --font-family: Inter, sans-serif;
  --font-family-base: var(--font-family); /* default */

  /* Font Weight */
  --font-weight-regular: normal;
  --font-weight-base: var(--font-weight-regular); /* default */
  --font-weight-medium: 500;
  --font-weight-bold: bold;

  /* Font sizes. */
  --font-size-xxs: 0.5rem;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.25rem;
  --font-size-5xl: 3rem;
  --font-size-6xl: 3.75rem;

  /* Line heights. */
  --line-height-xs: 1.25rem;
  --line-height-sm: 1.25rem;
  --line-height-base: 1.5rem;
  --line-height-lg: 1.75rem;
  --line-height-xl: 2rem;
  --line-height-2xl: 2.25rem;
  --line-height-3xl: 2.25rem;
  --line-height-4xl: 2.75rem;
  --line-height-5xl: 3.4375rem;
  --line-height-6xl: 4.375rem;

  /* Fonts */
  --font-xxs-regular: var(--font-weight-regular) var(--font-size-xxs) /var(--line-height-xxs) var(--font-family);
  --font-xs-medium: var(--font-weight-medium) var(--font-size-xxs) /var(--line-height-xxs) var(--font-family);
  --font-xxs-bold: var(--font-weight-bold) var(--font-size-xxs) /var(--line-height-xxs) var(--font-family);

  --font-xs-regular: var(--font-weight-regular) var(--font-size-xs) /var(--line-height-xs) var(--font-family);
  --font-xs-medium: var(--font-weight-medium) var(--font-size-xs) /var(--line-height-xs) var(--font-family);
  --font-xs-bold: var(--font-weight-bold) var(--font-size-xs) /var(--line-height-xs) var(--font-family);

  --font-sm-regular: var(--font-weight-regular) var(--font-size-sm) /var(--line-height-sm) var(--font-family);
  --font-sm-medium: var(--font-weight-medium) var(--font-size-sm) /var(--line-height-sm) var(--font-family);
  --font-sm-bold: var(--font-weight-bold) var(--font-size-sm) /var(--line-height-sm) var(--font-family);

  --font-base-regular: var(--font-weight-regular) var(--font-size-base) /var(--line-height-base) var(--font-family);
  --font-base-medium: var(--font-weight-medium) var(--font-size-base) /var(--line-height-base) var(--font-family);
  --font-base-bold: var(--font-weight-bold) var(--font-size-base) /var(--line-height-base) var(--font-family);

  --font-lg-regular: var(--font-weight-regular) var(--font-size-lg) /var(--line-height-lg) var(--font-family);
  --font-lg-medium: var(--font-weight-medium) var(--font-size-lg) /var(--line-height-lg) var(--font-family);
  --font-lg-bold: var(--font-weight-bold) var(--font-size-lg) /var(--line-height-lg) var(--font-family);

  --font-xl-regular: var(--font-weight-regular) var(--font-size-xl) /var(--line-height-xl) var(--font-family);
  --font-xl-medium: var(--font-weight-medium) var(--font-size-xl) /var(--line-height-xl) var(--font-family);
  --font-xl-bold: var(--font-weight-bold) var(--font-size-xl) /var(--line-height-xl) var(--font-family);

  --font-2xl-regular: var(--font-weight-regular) var(--font-size-2xl) /var(--line-height-2xl) var(--font-family);
  --font-2xl-medium: var(--font-weight-medium) var(--font-size-2xl) /var(--line-height-2xl) var(--font-family);
  --font-2xl-bold: var(--font-weight-bold) var(--font-size-2xl) /var(--line-height-2xl) var(--font-family);

  --font-3xl-regular: var(--font-weight-regular) var(--font-size-3xl) /var(--line-height-3xl) var(--font-family);
  --font-3xl-medium: var(--font-weight-medium) var(--font-size-3xl) /var(--line-height-3xl) var(--font-family);
  --font-3xl-bold: var(--font-weight-bold) var(--font-size-3xl) /var(--line-height-3xl) var(--font-family);

  --font-4xl-regular: var(--font-weight-regular) var(--font-size-4xl) /var(--line-height-4xl) var(--font-family);
  --font-4xl-medium: var(--font-weight-medium) var(--font-size-4xl) /var(--line-height-4xl) var(--font-family);
  --font-4xl-bold: var(--font-weight-bold) var(--font-size-4xl) /var(--line-height-4xl) var(--font-family);

  --font-5xl-regular: var(--font-weight-regular) var(--font-size-5xl) /var(--line-height-5xl) var(--font-family);
  --font-5xl-medium: var(--font-weight-medium) var(--font-size-5xl) /var(--line-height-5xl) var(--font-family);
  --font-5xl-bold: var(--font-weight-bold) var(--font-size-5xl) /var(--line-height-5xl) var(--font-family);

  --font-6xl-regular: var(--font-weight-regular) var(--font-size-6xl) /var(--line-height-6xl) var(--font-family);
  --font-6xl-medium: var(--font-weight-medium) var(--font-size-6xl) /var(--line-height-6xl) var(--font-family);
  --font-6xl-bold: var(--font-weight-bold) var(--font-size-6xl) /var(--line-height-6xl) var(--font-family);
}

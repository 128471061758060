.screen {
  align-items: center;
  color: var(--color-grey-900);
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  padding: var(--spacing-2) var(--spacing-5);
}

.screen__visual {
  color: var(--color-grey-700);
  font-size: 78px;
}

.screen__title {
  font: var(--font-3xl-medium);
  letter-spacing: var(--font-heading-letter-spacing-h012);
  margin: var(--spacing-7) 0 0;
}

.screen__message {
  font: var(--font-base-regular);
  margin: var(--spacing-7) 0 0;
  text-align: center;
}

.screen__message a {
  color: var(--color-primary-600);
  text-decoration: none;
  transition: color var(--transition-fast);
}

.screen__message a:hover {
  color: var(--color-grey-900);
}

.input-text-area {
  display: flex;
  flex-direction: column;
}

.input-text-area__input {
  border-radius: var(--spacing-1);
  border: 1px solid var(--color-grey-300);
  transition: all var(--transition-fast);
  color: var(--color-grey-900);
  width: 100%;
  height: 100%;
  outline: 0;
  padding: var(--spacing-2) var(--spacing-3);
  text-align: inherit;
}

.input-text-area__input:disabled {
  background-color: var(--color-grey-100);
}

.input-text-area__input:focus {
  border-color: var(--color-primary-500);
}

.input-text-area__input.input-text-area__input--error {
  border-color: var(--color-error-300);
}

.input-text-area__input.input-text-area__input--success {
  border-color: var(--color-success-300);
}

.input-text-area__input:not(:disabled):hover {
  background-color: var(--color-grey-50);
}

.input-text-area__input::placeholder {
  color: var(--color-grey-500);
}

.input-text-area__label {
  align-items: center;
  color: var(--color-grey-900);
  cursor: pointer;
  display: flex;
  font: var(--font-sm-medium);
  height: var(--spacing-6);
  justify-content: inherit;
}

.input-text-area__label {
  font: var(--font-sm-medium);
  color: var(--color-grey-700);
  margin-bottom: var(--spacing-1);
}

.input-text-area__hint-text {
  display: block;
  font: var(--font-sm-regular);
  margin-top: var(--spacing-2);
  color: var(--color-grey-500);
}

.input-text-area__hint-text.input-text-area__hint-text--error {
  color: var(--color-error-700);
}

.input-text-area__hint-text.input-text-area__hint-text--success {
  color: var(--color-success-500);
}

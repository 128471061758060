.indicator {
  background: var(--color-grey-700);
  border-radius: 9999px;
  display: block;
  width: var(--spacing-3);
  height: var(--spacing-3);
}

.indicator--available {
  background: var(--color-primary-600);
}

.indicator--unavailable {
  background: var(--color-error-700);
}

.indicator--partly-available {
  background: linear-gradient(180deg, var(--color-primary-600) 50%, var(--color-primary-100) 50%);
}

.indicator--partly-unavailable {
  background: linear-gradient(180deg, var(--color-error-700) 50%, var(--color-error-50) 50%);
}

.indicator--partly-both {
  background: var(--color-warning-700);
}

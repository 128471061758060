.datepicker {
  --datepicker-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); /* from datepicker.css */

  background: var(--color-bw-50);
  border-radius: var(--spacing-2);
  box-shadow: var(--datepicker-shadow);
  font: var(--font-sm-medium);
  width: 100%;
}

.datepicker__grid {
  display: grid;
  grid-gap: var(--spacing-3) 0;
  grid-template-columns: repeat(7, max-content);
  padding: var(--spacing-1);
  justify-content: center;
}

.datepicker__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-7);
}

.datepicker__month-label {
  font: var(--font-base-regular);
}

.datepicker__grid-cell {
  /* noop */
}

.datepicker__grid-cell--header {
  color: var(--color-grey-900);
  font: var(--font-xs-regular);
  margin-bottom: var(--spacing-5);
  opacity: 0.6;
  text-align: center;
}

.datepicker__interval {
  color: var(--color-grey-900);
  font: var(--font-sm-medium);
  margin-top: var(--spacing-5);
  text-align: center;
}

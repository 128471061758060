.dialog-overlap {
  max-width: var(--dialog-max-width-sm);
  width: 100vw;
}

.dialog-overlap__text {
  font: var(--font-base-medium);
  margin: var(--spacing-5) 0;
}

.dialog-overlap__text:first-child {
  margin-top: 0;
}

.dialog-overlap__text:last-child {
  margin-bottom: 0;
}

.dialog-overlap__list {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--spacing-3) 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.dialog-overlap__list-item {
  font: var(--font-sm-medium);
}

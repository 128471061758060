.offerer {
  background: var(--color-grey-200);
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--spacing-2) 0;
  padding: var(--spacing-4);
}

.offerer__title {
  color: var(--color-grey-900);
  font: var(--font-sm-medium);
}

.day-cell {
  background: none;
  border-radius: var(--spacing-1);
  border: 1px dashed var(--color-grey-800);
  cursor: pointer;
  height: 100%;
  min-height: 52px;
  position: relative;
  width: 100%;
}

.day-cell:hover {
  background: var(--color-primary-100);
  border-color: var(--color-primary-500);
}

.day-cell--with-preset {
  /* Will be overridden in component */
  background-color: var(--color-grey-800);
  border: none;
  cursor: pointer;
  font: var(--font-base-regular);
  overflow: hidden;
  width: 100%;
}

.day-cell__button {
  align-items: center;
  background: none;
  border: 0;
  color: var(--color-grey-800);
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.day-cell__button:disabled {
  opacity: 0.6;
  cursor: initial;
}

.day-cell--with-preset::before {
  background: currentColor;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}

.day-cell--with-preset:hover .day-cell__button {
  color: var(--color-grey-800);
}

.day-cell--resting-day {
  background-color: var(--color-grey-100);
  color: var(--color-grey-800);
}

.day-cell--resting-day:hover {
  background-color: var(--color-grey-100);
  color: var(--color-grey-800);
}

.day-cell--preset-select-open {
  overflow: initial;
}

.day-cell:hover:not(.day-cell--with-preset) .day-cell__button {
  color: var(--color-primary-500);
}

.dropdown {
  --open-transition-easing: linear;
  --open-transition-duration: 100ms;

  transition: border var(--transition-fast);
  position: relative;
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--spacing-1);
  border: 1px solid var(--color-grey-300);
}

.dropdown--without-border {
  border-color: transparent;
}

.dropdown--disabled {
  background-color: var(--color-grey-50);
}

.dropdown:not(.hide-focus-border):focus-within {
  border: 1px solid var(--color-primary-500);
}

.dropdown__button {
  font: var(--font-sm-medium);
  color: var(--color-grey-700);
  align-items: center;
  background: 0;
  border: 0;
  display: flex;
  line-height: inherit;
  outline: 0;
  padding: 0;
}

.dropdown__button:not(:disabled) {
  cursor: pointer;
}

.dropdown__prefix-icon {
  margin-right: var(--spacing-3);
  color: var(--color-grey-500);
}

.dropdown__chevron {
  margin: 0 0 0 var(--spacing-2);
  transform: rotate(0deg);
  transition: transform var(--transition-fast);
}

.dropdown--chevron-hidden .dropdown__button::after {
  display: none;
}

.dropdown__menu {
  position: absolute;
  top: calc(100% + var(--spacing-1));
  left: 0;
  background: var(--color-bw-50);
  border-radius: var(--spacing-2);
  border: 1px solid var(--color-grey-300);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  list-style: none;
  margin: 0;
  max-height: 50vh;
  min-width: calc(100% + var(--spacing-8));
  max-width: calc(100% + var(--spacing-14));
  overflow-y: auto;
  padding: 0;
  text-align: left;
  transform: scaleY(1);
  transform-origin: top;
  transition: transform var(--open-transition-easing) var(--open-transition-duration);
  z-index: 1000;
}

.dropdown__menu--hidden {
  transform: scaleY(0);
}

.dropdown__chevron--menu-hidden {
  transform: rotate(180deg);
}

.dropdown__menu-item {
  border: none;
  color: var(--color-grey-700);
  background-color: var(--color-bw-50);
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font: var(--font-sm-regular);
  padding: var(--spacing-2) var(--spacing-3);
  text-align: left;
  text-decoration: none;
  transition: all var(--transition-fast);
  white-space: nowrap;
  width: 100%;
}

.dropdown__menu-item-icon {
  color: var(--color-grey-500);
  margin-right: var(--spacing-2);
}

.dropdown__menu-item:focus {
  background-color: var(--color-primary-50);
  outline: none;
}

.dropdown__menu-item--pressed {
  background-color: var(--color-primary-500);
  color: var(--color-bw-50);
}

.dropdown__menu-item:disabled {
  background-color: transparent;
  cursor: default;
  color: var(--color-grey-500);
}

.dropdown__menu-item:disabled.dropdown__menu-item-icon {
  color: var(--color-grey-400);
}

.dropdown__menu-item:focus.dropdown__menu-item-icon {
  color: var(--color-grey-500);
}

.dropdown__menu-item:hover:not(:disabled) {
  background-color: var(--color-grey-50);
  color: var(--color-grey-900);
}

.dropdown__menu-item:hover:not(:disabled).dropdown__menu-item-icon {
  color: var(--color-grey-700);
}

.dropdown__menu-item-separator {
  background: rgba(0, 0, 0, 0.15);
  height: 1px;
}

.dropdown__menu-item-text {
  color: var(--color-grey-500);
  font: var(--font-sm-regular);
  cursor: default;
  max-width: 100%;
  white-space: pre-wrap;
}

.dropdown__menu-item-subtitle {
  color: var(--color-grey-500);
  font: var(--font-xs-medium);
  text-transform: uppercase;
  cursor: default;
}

.dropdown__menu-item:hover:not(:disabled).dropdown__menu-item-subtitle,
.dropdown__menu-item:hover:not(:disabled).dropdown__menu-item-text {
  background-color: var(--color-bw-50);
  color: var(--color-grey-500);
}

.dropdown__menu--hidden.dropdown__menu-item {
  opacity: 0;
  transition-delay: 0ms;
}

.dropdown__menu--left {
  left: initial;
  right: 0;
}

.search {
  /* noop */
}

.search__input {
  appearance: none;
  border: #f6f6f6 1px solid;
  border-radius: 9999px;
  color: #adadad;
  font-size: 14px;
  line-height: 1.428571429;
  outline: 0;
  padding: 4px 12px 4px 30px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;
}

.search__input:focus {
  border-color: var(--shyftplan-green);
}

.search__label {
  display: block;
  margin: var(--spacing-3);
  position: relative;
}

.search__label-icon {
  color: #adadad;
  font-size: 18px;
  left: 0;
  padding: 6px 8px 0;
  position: absolute;
  top: 0;
}

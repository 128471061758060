.column-assignment__filter-section {
  border-bottom: var(--color-grey-100) 1px solid;
  transition: opacity var(--transition-default), max-height var(--transition-default);
}

.column-assignment__list {
  /* 8px is also the default for Firefox, the only browser supporting scrollbar-width */
  --scrollbar-width: 8px;

  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  position: relative;
}

.column-assignment__list::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.column-assignment__list:hover {
  scrollbar-color: var(--color-grey-200) transparent;
}

.column-assignment__list:hover::-webkit-scrollbar-thumb {
  background: var(--color-grey-200);
  border-radius: var(--spacing-1);
}

.column-assignment__list--loading .column-assignment__item {
  opacity: 0.5;
  pointer-events: none;
}

.transition-slide-enter.column-assignment__filter-section,
.transition-slide-leave-to.column-assignment__filter-section {
  opacity: 0;
  max-height: 0;
}

.transition-slide-enter-to.column-assignment__filter-section ,
.transition-slide-leave.column-assignment__filter-section {
  opacity: 1;
  max-height: 400px;
}

.column-assignment__loading-indicator {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.column-assignment__loading-indicator:not(:last-child) {
  margin-bottom: -3px;
}

.column-assignment__availability-indicator {
  top: 0;
  right: calc(-1 * var(--spacing-1));
  position: absolute;
}

.column-assignment__conflict-indicator {
  left: calc(-1 * var(--spacing-1));
  position: absolute;
  bottom: calc(-1 * var(--spacing-2));
}

.column-assignment__join-request-indicator {
  right: calc(-1 * var(--spacing-1));
  position: absolute;
  bottom: calc(-1 * var(--spacing-2));
}

.column-assignment__item {
  overflow: hidden;
  padding: var(--spacing-1) var(--spacing-2);
  position: relative;
  transition: background var(--transition-fast), opacity var(--transition-fast);
  display: flex;
}

.column-assignment__item:hover {
  background: #f7f8fa80;
}

.column-assignment__item:not(:last-child) {
  border-bottom: var(--color-grey-100) 1px solid;
}

.column-assignment__employment {
  flex: 1;
  width: calc(100% - 50px); /* 100 - action icon button */
  padding-right: var(--spacing-1);
}

.column-assignment__button {
  background-color: var(--color-success-100);
  color: var(--color-success-700);
}


.column-assignment__button:focus,
.column-assignment__button:hover {
  background-color: var(--color-success-200);
}

.column-assignment__button--unassign {
  background-color: var(--color-grey-100);
  color: var(--color-grey-700);
  margin-left: var(--spacing-1);
}

.column-assignment__button--unassign:focus,
.column-assignment__button--unassign:hover {
  background-color: var(--color-grey-200);
}

.column-assignment__tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}

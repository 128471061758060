.employment-item {
  align-items: center;
  display: grid;
  grid-gap: 0 var(--spacing-1);
  grid-template-columns: max-content 1fr 28px;
  padding: var(--spacing-1) var(--spacing-2);
}

.employment-item:not(:last-child) {
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
}

.employment-item--selected {
  background: var(--color-primary-100);
}

.employment-item__info {
  overflow: hidden;
}

.employment-item__name {
  color: #333;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.employment-item__hours-report {
  display: block;
  color: #adadad;
  font-size: 12px;
  line-height: 16px;
}

.employment-item__positions {
  display: flex;
  flex-wrap: wrap;
}

.employment-item__position-dot {
  display: flex;
}

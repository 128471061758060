.cell-actions {
  text-align: right;
}

.cell-actions__link {
  --button-icon-dimension: var(--button-icon-size-sm);

  /* Fix for Chrome issue: https://www.chromestatus.com/feature/5213452823953408 */
  height: var(--button-icon-dimension);
  width: var(--button-icon-dimension);
  justify-content: center;
  padding: 0;

  /* override LinkButton style */
  box-shadow: none;
}

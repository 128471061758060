.preset-select {
  background: var(--color-bw-50);
  border-radius: var(--spacing-2);
  box-shadow: 0 5px 34px rgba(0, 0, 0, 0.1);
  left: 50%;
  min-width: 210px;
  position: absolute;
  top: 33%;
  transform: translateX(-50%);
  z-index: 9999;
}

.preset-select__header {
  align-items: center;
  border-bottom: var(--color-grey-200) 1px solid;
  color: var(--color-grey-900);
  display: flex;
  font: var(--font-lg-bold);
  height: var(--spacing-13);
  line-height: 17px;
  padding: 0 var(--spacing-3);
  width: 100%;
}

.preset-select__list {
  display: grid;
  grid-auto-flow: row;
  grid-gap: var(--spacing-3) 0;
  padding: var(--spacing-3);
}

.preset-select__list-item {
  /* Will be overridden in component */
  background-color: var(--color-grey-200);
  align-items: center;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  display: flex;
  font: var(--font-base-medium);
  min-height: 32px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.preset-select__list-item::before {
  content: "";
  width: 2px;
  top: 0;
  left: 0;
  height: 100%;
  position: absolute;
  background: currentColor;
}

.preset-select__list-item--resting-day {
  background-color: var(--color-grey-200);
  color: var(--color-grey-800);
}

.preset-select__list-item-label {
  color: var(--color-grey-800);
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}

.sorting {
  --order-indicator-size: 4px;

  display: flex;
}

.sorting__tabs {
  width: 100%;
}

.sorting__tab-label {
  align-items: center;
  background: var(--color-bw-50);
  display: grid;
  grid-gap: 0 var(--spacing-1);
  grid-template-columns: max-content max-content;
  justify-content: center;
  margin: -10px calc(-1 * var(--spacing-5));
  padding: 10px var(--spacing-5);
}


.sorting__radio-button {
  color: var(--color-grey-800);
  cursor: pointer;
  display: flex;
  justify-content: center;
  opacity: 0.5;
  padding: var(--spacing-3);
  position: relative;
  transition: color var(--transition-fast), opacity var(--transition-fast);
  width: 100%;
}

.sorting__radio-button--selected {
  color: var(--color-primary-600);
  opacity: 1;
}

.sorting__input {
  width: 0;
  height: 0;
  visibility: hidden;
}

.sorting__order-indicator {
  appearance: none;
  display: block;
  left: calc(50% - var(--order-indicator-size) + var(--spacing-5));
  position: absolute;
  top: calc(50% - var(--order-indicator-size) * 1.5);
}

.sorting__order-indicator::before {
  border: transparent var(--order-indicator-size) solid;
  border-bottom-color: var(--color-primary-600);
  content: '';
  display: block;
  height: 0;
  transform-origin: 50% calc(100% - var(--order-indicator-size) / 2);
  transition: transform var(--transition-fast);
  width: 0;
}

.sorting__order-indicator:checked::before {
  transform: rotate(180deg);
}

.header {
  align-items: center;
  position: relative;
  color: var(--color-bw-50);
  display: flex;
  justify-content: flex-end;
  background: var(--color-bw-50);
  height: 64px;
  box-shadow: var(--shadow-sm);
}

.header__dropdown {
  align-items: center;
  color: var(--color-grey-700);
  cursor: pointer;
  display: flex;
  height: 100%;
  font: var(--font-sm-regular);
  padding: 0 var(--spacing-5);
}

.header__dropdown:hover {
  background-color: var(--color-primary-100);
}

.header__dropdown-inner {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.header__user {
  margin: 0 0 0 var(--spacing-1);
}

@media (max-width: 767px) {
  .header__user {
    display: none;
  }
}

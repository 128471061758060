.shiftplan-selector {
  border-radius: var(--spacing-1);
  color: var(--color-grey-800);
  font: var(--font-base-regular);
  line-height: 1;
  padding: var(--spacing-3);
  transition: background var(--transition-fast);
}

.shiftplan-selector__shiftplan {
  color: var(--color-warning-700);
  font: inherit;
  font: var(--font-sm-regular);
}

.shiftplan-selector__shiftplan:focus,
.shiftplan-selector__shiftplan:hover {
  color: var(--color-warning-700);
}

.shiftplan-selector__shiftplan--unpublished {
  color: var(--color-error-700);
}

.shiftplan-selector__shiftplan--unpublished:focus,
.shiftplan-selector__shiftplan--unpublished:hover {
  background: var(--color-error-50);
  color: var(--color-error-700);
}

.shiftplan-selector__shiftplan--expired {
  color: var(--shyftplan-green);
}

.shiftplan-selector__shiftplan--expired:focus,
.shiftplan-selector__shiftplan--expired:hover {
  background: var(--color-primary-100);
  color: var(--shyftplan-green);
}

.shiftplan-selector__shiftplan--selected {
  font: var(--font-sm-bold);
}

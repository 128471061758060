.availability-aggregations {
  padding: 0 calc(var(--spacing-16) * 2);
  display: grid;
  grid-template-columns: 301px auto;
}

.availability-aggregations__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-7);
  grid-column: span 2;
}

.availability-aggregations__title {
  font: var(--font-3xl-regular);
}

.availabiltiy-aggregations__filters {
  /* noop */
}

.button-square {
  --background-color: transparent;
  --hover-color: #60c560;
  --text-color: var(--shyftplan-green);

  border: 0;
  margin: 0;
  outline: none;
  padding: var(--spacing-2);
  border-radius: var(--spacing-1);
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: inset 0 0 0 2px currentColor;
}

.button-square:hover {
  color: var(--hover-color);
}

.button-square:disabled {
  color: var(--neutral-comet);
  opacity: 0.6;
}

th,
td {
  font-weight: initial;
}

.table__wrapper {
  --table-vertical-gap: var(--spacing-3);
  --table-horizontal-gap: var(--spacing-6);
  --table-gap: var(--table-vertical-gap) var(--table-horizontal-gap);

  flex: 1;
  display: flex;
  flex-direction: column;
  font: var(--font-base-regular);
  overflow: hidden;
  padding: var(--spacing-1) 0;
}

.table__controls {
  display: flex;
  color: var(--color-grey-600);
  margin-bottom: var(--spacing-5);
}

.table__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.table__search-icon {
  margin-right: var(--spacing-3);
}

.table__search-input {
  border: none;
  outline: none;
  color: inherit;
  flex: 1;
}

.table__pagination {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.table__pagination--hidden {
  visibility: hidden;
}

.table__pagination-info {
  margin-right: var(--spacing-13);
}

.table__pagination-controls {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-5);
}

.table__auxiliary-controls {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0 var(--spacing-7);
  margin: 0 0 0 var(--spacing-7);
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  color: var(--color-grey-900);
  display: table;
  position: relative;
  table-layout: fixed;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.table__scroll-container {
  height: 100%;
  overflow-y: auto;
}

.table__header {
  margin-bottom: var(--spacing-5);
  line-height: var(--spacing-8);
  border-radius: var(--spacing-3);
  font-weight: initial;
}

.table__body {
  display: contents;
}

.table__body tr {
  transition: opacity var(--transition-timing-function-default) calc(var(--transition-duration-default) / 2);
}

.table--loading .table__body tr {
  opacity: 0.5;
  pointer-events: none;
}

.table__header-cell {
  padding: var(--table-gap);
  background-color: var(--color-grey-50);
}

.table__header-select-cell {
  min-width: 14px;
  width: 14px;
}

.table__header-cell--stretch {
  text-align: left;
}

.table__body-cell {
  padding: var(--table-gap);
  overflow: hidden;
  text-overflow: ellipsis;
}

.table__body-cell--stretch {
  text-align: left;
  width: 100%;
}

.table__body-cell--nothing-found {
  color: var(--color-grey-700);
  font: var(--font-lg-medium);
  padding: var(--spacing-10) var(--table-gap);
}

.table__body-cell--left {
  justify-self: start;
}

.table__loading-indicator {
  animation: slide-right 1.7s ease infinite;
  background: var(--color-primary-200) linear-gradient(90deg, var(--color-primary-600) 0%, var(--color-primary-600) 100%);
  background-repeat: no-repeat;
  background-position: calc(-100% / 3) center;
  background-size: 25%;
  height: 3px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 42px;
  width: 100%;
}

@keyframes slide-right {
  0% {
    background-position: calc(-100% / 3) center;
  }

  10% {
    background-position: calc(-100% / 3) center;
  }

  90% {
    background-position: calc(100% + 100% / 3) center;
  }

  100% {
    background-position: calc(100% + 100% / 3) center;
  }
}

.tabs {
  display: flex;
  flex-flow: column nowrap;
}

.tabs__tablist {
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  min-height: 38px;
}

.tabs__tab {
  background: transparent;
  border: 0;
  border-bottom: transparent 2px solid;
  color: var(--color-grey-500);
  font: var(--font-sm-medium);
  outline: 0;
  padding: var(--spacing-2) var(--spacing-4);
  transition: border var(--transition-fast), color var(--transition-fast);
  white-space: nowrap;
  position: relative;
}

.tabs__tab-selected,
.tabs__tab-selected:focus {
  border-bottom-color: var(--color-primary-500);
  color: var(--color-primary-500);
}

.tabs__tab-selected:hover {
  border-bottom-color: var(--color-primary-600);
  color: var(--color-primary-600);
}

.tabs__tab-selected:not(:disabled),
.tabs__tab-selected:focus:not(:disabled) {
  border-bottom-color: var(--color-primary-500);
  color: var(--color-primary-500);
}

.tabs__tab-selected:hover:not(:disabled):not(:focus) {
  border-bottom-color: var(--color-primary-600);
  color: var(--color-primary-600);
}

.tabs__tab:disabled,
.tabs__tab:disabled:hover,
.tabs__tab:disabled:focus {
  border-bottom-color: transparent;
  color: var(--color-grey-300);
}

.tabs__tab:not(:disabled):not(.tabs__tab-selected) {
  color: var(--color-grey-400);
  cursor: pointer;
}

.tabs__tab:focus:not(:disabled):not(.tabs__tab-selected),
.tabs__tab:hover:not(:disabled):not(.tabs__tab-selected) {
  color: var(--color-grey-500);
  border-bottom-color: var(--color-grey-400);
  cursor: pointer;
}

.tabs__panel {
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  overflow: auto;
}

.tabs__tab-content {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.tabs__tab-icon {
  margin-right: var(--spacing-2);
}

.input-pill-select {
  position: relative;
}

.input-pill-select__input {
  outline: 0;
  border: 0;
  background: 0;
  flex: 1;
  min-width: var(--spacing-16);
  margin: var(--spacing-1);
}

.input-pill-select__input:first-child {
  grid-column: span 2;
}

.input-pill-select__select {
  position: absolute;
  z-index: 1000;
  top: 30px;
  visibility: hidden;
}

.input-pill-select:focus-within .input-pill-select__select {
  visibility: visible;
}

.input-pill-select__inner {
  display: flex;
  flex-flow: row nowrap;
  transition: border var(--transition-fast);
  position: relative;
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--spacing-1);
  border: 1px solid var(--color-grey-300);
  font: var(--font-sm-medium);
  color: var(--color-grey-700);
  align-items: center;
  background: 0;
  line-height: inherit;
  outline: 0;
  width: 100%;
  cursor: pointer;
}

.input-pill-select__label {
  cursor: default;
  display: block;
  font: var(--font-sm-medium);
  color: var(--color-grey-700);
  margin-bottom: var(--spacing-1);
}

.input-pill-select__values-and-search {
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 1;
  flex-grow: 1;
}

.input-pill-select__suffix-wrapper {
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: center;
}

.input-pill-select__inner--active {
  border-color: var(--color-primary-500);
}

.input-pill-select__inner.input-pill-select__inner--error,
.input-pill-select__inner.input-pill-select__inner--error:focus-within {
  border-color: var(--color-error-500);
}

.input-pill-select__inner.input-pill-select__inner--success,
.input-pill-select__inner.input-pill-select__inner--success:focus-within {
  border-color: var(--color-success-500);
}

.input-pill-select__input::placeholder {
  color: var(--color-grey-700);
  opacity: 0.5;
  transition: color var(--transition-fast);
}

.input-pill-select__suffix-icon {
  margin-left: var(--spacing-2);
  flex-shrink: 0;
}

.input-pill-select__hint-text {
  display: block;
  font: var(--font-sm-regular);
  margin-top: var(--spacing-2);
  color: var(--color-grey-500);
}

.input-pill-select__hint-text.input-pill-select__hint-text-success,
.input-pill-select__suffix-icon--success {
  color: var(--color-success-500);
}

.input-pill-select__hint-text.input-pill-select__hint-text--error,
.input-pill-select__suffix-icon--error {
  color: var(--color-error-500);
}

.input-pill-select__chevron {
  margin: 0 0 0 auto;
  transform: rotate(0deg);
  transition: transform var(--transition-fast);
  flex-shrink: 0;
}

.input-pill-select__chevron--menu-hidden {
  transform: rotate(180deg);
}

.input-pill-select-badge {
  margin: var(--spacing-1);
}

.input-pill-select--disabled,
.input-pill-select--disabled .input-pill-select__chevron,
.input-pill-select--disabled .input-pill-select__inner {
  background-color: var(--color-grey-50);
  cursor: default;
}

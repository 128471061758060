.shifts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;
  overflow: hidden;
}

.shifts__column {
  display: flex;
  flex-flow: column nowrap;
}

.shifts__spinner-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: calc(100vw - 2 * var(--spacing-10));
}

.shifts__spinner {
  margin: 0 auto;
  font-size: var(--font-size-heading-md);
}

.shifts__column:not(:last-child) {
  border-right: var(--color-grey-100) 1px solid;
}

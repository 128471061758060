.leave-shift {
  /* noop */
}

.leave-shift__message {
  color: var(--color-grey-900);
  font: var(--font-base-medium);
  margin: 0;
  padding: var(--spacing-7) var(--spacing-4);
  text-align: center;
}

.leave-shift__message-state {
  color: var(--color-grey-900);
  font: var(--font-base-medium);
  margin: 0;
  padding: 0 var(--spacing-4) var(--spacing-7);
  text-align: center;
}

.leave-shift__button {
  margin: var(--spacing-7) auto 0;
}

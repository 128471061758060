.summary {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: var(--spacing-7) 0;
  padding: 0 var(--spacing-7);
}

.summary--hidden {
  visibility: hidden;
}

.summary__title {
  color: var(--color-grey-800);
  font: var(--font-heading-h3);
  letter-spacing: var(--font-heading-letter-spacing-h345);
  margin: 0;
}

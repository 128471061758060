.dialog-shiftplan-publish {
  display: flex;
  flex-direction: column;
}

.dialog-shiftplan-publish__input-toggle {
  display: flex;
  flex-direction: row;
  place-content: space-between;
  margin: var(--spacing-4) 0;
}

.dialog-shiftplan-publish__input-textarea {
  width: 100%;
}

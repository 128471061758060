.navigation-button {
  align-items: center;
  color: var(--color-grey-300);
  font: var(--font-sm-medium);
  padding: var(--spacing-2);
  display: flex;
  overflow: hidden;
  width: 40px;
  height: 40px;
  position: relative;
  text-decoration: none;
  border-radius: var(--spacing-1);
}

.navigation-button:hover {
  overflow: initial;
  background-color: var(--color-grey-600);
}

.navigation-button--active {
  color: var(--color-bw-50);
}

.navigation-button__icon {
  /* fix for alignment glitch of old web app */
  transform: translateY(-2px);
}

.navigation-button__label {
  margin: 0 0 0 13px;

  /* fix for alignment glitch of old web app */
  transform: translateY(-1px);
}

@media (max-width: 1919px) {
  .navigation-button {
    justify-content: center;
  }

  .navigation-button__label {
    /* to increase accessibility, never hide label, just move out of viewport */
    margin: 0;
    text-indent: -99999px;
  }

  .navigation-button--active {
    background-color: var(--color-primary-500);
  }

  .navigation-button__badge {
    position: absolute;
    right: 3px;
    top: 0;
    border-radius: 50%;
  }
}

@media (min-width: 1920px) {
  .navigation-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    place-content: flex-start;
  }
}

.navigation-button__tooltip {
  background: var(--color-bw-100);
  border-radius: var(--spacing-1);
  color: var(--color-bw-50);
  font: var(--font-xs-regular);
  left: 100%;
  margin-left: var(--spacing-2);
  max-width: 0;
  opacity: 0;
  position: absolute;
  transition:
    opacity var(--transition-duration-default),
    max-width 0s,
    padding 0s;
  transition-delay:
    0s,
    var(--transition-duration-default),
    var(--transition-duration-default);
  white-space: nowrap;
}

.navigation-button__tooltip::before {
  border: transparent solid;
  border-right-color: var(--color-bw-100);
  border-width: var(--spacing-1) var(--spacing-1) var(--spacing-1) 0;
  content: '';
  height: 0;
  left: 0;
  margin: calc(var(--spacing-1)* -1) 0 0 calc(var(--spacing-1)* -1);
  position: absolute;
  top: 50%;
  width: 0;
}

.navigation-button:hover .navigation-button__tooltip {
  max-width: 200px;
  opacity: 0.9;
  padding: var(--spacing-1) var(--spacing-2);
  transition-delay: 0s;
  z-index: 99999;
}

.navigation-button:hover .navigation-button__tooltip::before {
  /* noop */
}

@media (max-width: 767px), (min-width: 1920px) {
  .navigation-button__tooltip {
    display: none;
  }
}

.navigation-button__container {
  display: flex;
  align-items: center;
}

.dialog-decline__message {
  background: 0;
  border: 0;
  outline: 0;
  padding: 0;
  resize: none;
  width: 100%;
}

.dialog-decline__message::placeholder {
  color: var(--color-grey-700);
  opacity: 0.5;
}

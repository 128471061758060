.sub-view {
  /* 8px is also the default for Firefox, the only browser supporting scrollbar-width */
  --scrollbar-width: 8px;

  display: grid;
  grid-template:
    "subheader subheader" max-content
    "subnavigation main" 1fr / 20% 1fr;
  height: 100%;
}

.sub-view__subheader {
  grid-area: subheader;
}

.sub-view__subnavigation {
  grid-area: subnavigation;
  padding-top: var(--spacing-7);
  overflow-y: auto;
}

.sub-view__main {
  border-left: var(--color-grey-200) 1px solid;
  grid-area: main;
  overflow-y: auto;
  padding: var(--spacing-7) var(--spacing-14);
}

.sub-view__subnavigation,
.sub-view__main {
  scrollbar-color: var(--color-grey-300) transparent;
  scrollbar-width: thin;
}

.sub-view__subnavigation::-webkit-scrollbar,
.sub-view__main::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.sub-view__subnavigation::-webkit-scrollbar-thumb,
.sub-view__main::-webkit-scrollbar-thumb {
  background: var(--color-grey-300);
}

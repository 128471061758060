.accordion {
  /* noop */
}

.accordion__lid {
  position: relative;
  display: flex;
  align-items: center;
  background: var(--color-bw-50);
  font: var(--font-xl-medium);
  height: 63px;
  margin: var(--spacing-3) 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 0 var(--spacing-7);
}

.accordion__lid-button {
  padding-left: var(--spacing-5);
  height: 100%;
  margin: 0;
  text-align: left;
  border-style: none;
  background: none;
  flex: 1;
  cursor: pointer;
}

.accordion__lid-arrow-button {
  border-radius: 9999px;
  height: var(--spacing-10);
  width: var(--spacing-10);
  border-style: none;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion__lid-arrow-button:hover {
  background: var(--color-grey-200);
}

.accordion__lid-content-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--color-bw-50);
  z-index: 1;
}

.accordion__content {
  /* noop */
}

.accordion__content--open {
  /* noop */
}

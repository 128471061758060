.monthpicker {
  --monthpicker-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  background: var(--color-white);
  border-radius: var(--spacing-2);
  box-shadow: var(--monthpicker-shadow);
  font: var(--font-sm-medium);
  width: 100%;
}

.monthpicker__grid {
  display: grid;
  grid-gap: var(--spacing-3) 0;
  grid-template-columns: repeat(3, max-content);
  padding: var(--spacing-1);
}

.monthpicker__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-7);
}

.monthpicker__year-label {
  font: var(--font-base-regular);
}

.monthpicker__month {
  background: none;
  border: 0;
  color: var(--color-grey-900);
  display: flex;
  justify-content: center;
  outline: 0;
  padding: 0 var(--spacing-1);
  transition: background var(--transition-fast);
}

.monthpicker__month-label {
  border-radius: 9999px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  padding: var(--spacing-3) var(--spacing-4);
  transition: background var(--transition-fast), color var(--transition-fast);
  width: 100%;
}

.monthpicker__month--selected .monthpicker__month-label {
  background-color: var(--color-primary-100);
}

.monthpicker__month--disabled {
  opacity: 0.5;
}

.monthpicker__month--disabled .monthpicker__month-label {
  cursor: default;
}

.monthpicker__month:not(.monthpicker__month--disabled):hover .monthpicker__month-label,
.monthpicker__month:not(.monthpicker__month--disabled):focus .monthpicker__month-label {
  background-color: var(--color-athens-light);
  color: var(--color-grey-900);
}

.employment-assignment {
  background: var(--color-bw-50);
  display: flex;
  height: 100%;
  overflow: hidden;
}

.employment-assignment__sidebar {
  /* 8px is also the default for Firefox, the only browser supporting scrollbar-width */
  --scrollbar-width: 8px;

  background: var(--color-grey-200);
  min-width: 309px;
  padding: 0 var(--spacing-8);
  scrollbar-width: thin;
  overflow-y: auto;
}

.employment-assignment__sidebar::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.employment-assignment__sidebar:hover::-webkit-scrollbar-thumb {
  background: var(--color-grey-700);
}

.employment-assignment__main {
  padding: var(--spacing-10) var(--spacing-8);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.employment-assignment__title {
  font: var(--font-2xl-medium);
  margin: 0 0 var(--spacing-8);
}

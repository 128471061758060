.copy-shiftplan__input-toggle {
    display: flex;
    flex-direction: row;
    place-content: space-between;
    margin: var(--spacing-4) 0;
}

.copy-shiftplan__datepicker {
  justify-self: center;
  width: initial;
}

.copy-shiftplan__date-frame {
  display: flex;
  flex-wrap: wrap;
}

.copy-shiftplan__side-by-side {
  flex-grow: 1;
  width: 50%;
}

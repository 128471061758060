.evaluation-container {
  align-items: flex-start;
  display: flex;
  flex-flow: row;
  gap: 0 var(--spacing-10);
  justify-content: flex-start;
}

.evaluation-container--flipped {
  flex-flow: row-reverse;
}

.evaluation-container__spinner {
  font-size: 2em;
  margin: 0 auto;
}

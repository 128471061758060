.button-reset-filters {}

.button-reset-filters__wrapper:not(:empty) {
  position: absolute;

  /* take spacing of the sidebar and it's scrollbar into account */
  width: calc(100% - var(--spacing-4));
  bottom: 0;
  left: 0;
  display: flex;
  padding: var(--spacing-3);
  justify-content: center;
  background-color: rgba(247, 248, 250, 0.5);
  backdrop-filter: blur(1px);
}

.attachment {
  overflow: hidden;
}

.attachment__icon {
  height: var(--spacing-11);
  justify-content: center !important;
  width: var(--spacing-11);
}

.attachment__text {
  font: var(--font-lg-medium);
  margin-top: var(--spacing-3);
}

.attachment__preview {
  display: flex;
  align-items: center;
}

.attachment__preview-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
  flex: 1;
  overflow: hidden;
}

.attachment__preview-icon {
  flex: 1;
  text-align: center;
}

.attachment__name {
  color: var(--color-grey-700);
  font: var(--font-sm-medium);
  margin-top: var(--spacing-1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dialog-rotation-assignment__conflict-list {
  list-style: circle;
  list-style-position: inside;
  margin-bottom: 0;
  padding: 0;
}

.dialog-rotation-assignment__conflict-list-item {
  font: var(--font-sm-bold);
}

.dialog-rotation-assignment__unassignment {
  display: flex;
  flex-direction: column;
}

.dialog-rotation-assignment__unassignment-radio-input:not(:last-child) {
  margin-bottom: var(--spacing-4);
}

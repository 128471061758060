/* Based on https://codepen.io/_fbrz/pen/ljuJn */

.spinner {
  --spinner-size: 1em;
  --spinner-size-half: calc(var(--spinner-size) / 2);
  --spinner-rim-size: calc(var(--spinner-size) / 4);
  --spinner-rim-size-small: calc(var(--spinner-size) / 15);

  color: currentColor;
  height: var(--spinner-size);
  position: relative;
  width: var(--spinner-size);
}

.spinner__icon {
  animation: animate-spinner-rotation 1.5s linear infinite;
  color: currentColor;
  clip: rect(0, var(--spinner-size), var(--spinner-size), var(--spinner-size-half));
  height: var(--spinner-size);
  width: var(--spinner-size);
  position: absolute;
  left: calc(50% - var(--spinner-size-half));
  top: calc(50% - var(--spinner-size-half));
}

.spinner__icon::after {
  animation: animate-spinner-rim 1.5s ease-in-out infinite;
  content: '';
  display: block;
  border-radius: 50%;
  clip: rect(0, var(--spinner-size), var(--spinner-size), var(--spinner-size-half));
  position: absolute;
  height: var(--spinner-size);
  width: var(--spinner-size);
}

@keyframes animate-spinner-rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(220deg);
  }
}

@keyframes animate-spinner-rim {
  0% {
    box-shadow: inset currentColor 0 0 0 var(--spinner-rim-size);
    transform: rotate(-140deg);
  }

  50% {
    box-shadow: inset currentColor 0 0 0 var(--spinner-rim-size-small);
  }

  100% {
    box-shadow: inset currentColor 0 0 0 var(--spinner-rim-size);
    transform: rotate(140deg);
  }
}

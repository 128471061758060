.item {
  align-items: center;
  color: var(--color-grey-800);
  display: grid;
  font: var(--font-sm-medium);
  grid-gap: 0 var(--spacing-2);
  grid-template-columns: max-content 1fr max-content;
  list-style-position: inside;
  list-style-type: none;
  padding: var(--spacing-2) var(--spacing-3);
}

.item__label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.item:not(:last-child) {
  border-bottom: var(--color-grey-200) 1px solid;
}
